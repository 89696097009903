import { api } from "../../contexts/AuthContext";
import Emitter from "../EventEmitter";

export const createTag = (data: Omit<VisualTag, "_id">) => {
	return new Promise((resolve, reject) => {
		api
			.post("visual-tags/create-tag", data)
			.then((response) => {
				Emitter.emit("VISUAL_TAGS_UPDATED", response);
				resolve(response);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

export const updateTag = (data: VisualTag) => {
	return new Promise((resolve, reject) => {
		api
			.put("visual-tags/update-tag", data)
			.then((response) => {
				Emitter.emit("VISUAL_TAGS_UPDATED", response);
				resolve(response);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

export const deleteTag = (data: VisualTag) => {
	return new Promise((resolve, reject) => {
		api
			.delete("visual-tags/delete-tag", { data })
			.then((response) => {
				Emitter.emit("VISUAL_TAGS_UPDATED", response);
				resolve(response);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

export const getTags = () => {
	return new Promise((resolve, reject) => {
		api
			.get("visual-tags/get-tags")
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject(error);
			});
	});
};
