import {
	Col,
	Empty,
	Flex,
	Row,
	Form,
	Input,
	Popconfirm,
	Space,
	Table,
	Typography,
} from "antd";
import { updateAccessRequest } from "../services/api-server/access_requests";
import dayjs from "dayjs";
import {
	findParent,
	getComponentRoles,
	setComponentViewer,
} from "../utils/utils";
import { socket } from "../utils/socket";
import { useEffect, useState } from "react";
import { useAccessRequestContext } from "../contexts/AccessRequestContext";
import { useSelector } from "react-redux";
import {
	sendSubmissionApproveEmail,
	sendSubmissionRejectEmail,
} from "../services/api-server/sendgrid";
import { getUser } from "../services/api-server/usermgt";
import { useForm } from "antd/es/form/Form";
import { saveMenu } from "../services/api-server/menu";
import Emitter from "../services/EventEmitter";
import _ from "lodash";
import { useComponent } from "../contexts/ComponentContext";
import { useMenu } from "../contexts/MenuContextV2";

type AccessRequestTabProps = {
	onApprove?: () => void;
	readonly?: boolean;
	users?: Array<any>;
};

const { Link, Text } = Typography;

const AccessRequestTab = ({
	onApprove = () => {},
	readonly = true,
	users = [],
}: AccessRequestTabProps) => {
	const [loading, setLoading] = useState(false);
	const { loading: requestsLoading, accessRequests } =
		useAccessRequestContext();
	const user = useSelector((state: any) => state?.user);
	const mode = useSelector((state: any) => state.mode);
	const testRecipients = useSelector((state: any) => state.testRecipients);
	const { menu } = useMenu();
	const { menuItem } = useComponent();

	const [form] = useForm();

	const handleApprove = (request: Partial<AccessRequest>) => {
		return async () => {
			const { requesterId = "" } = request;

			try {
				setLoading(true);

				const requestedUser = await getUser(request?.requesterId || "");
				const roles = await getComponentRoles(menuItem);
				const viewerRole = roles?.find(
					(role: any) => role?.name?.indexOf("viewer") !== -1
				);

				await updateAccessRequest({ ...request, status: "Approved" });

				await setComponentViewer(
					requesterId,
					viewerRole,
					user?.name,
					roles,
					menuItem.key
				);

				const root = findParent(menu, menuItem.key);

				const traverse = (mItem: any) => {
					if (mItem?.key === menuItem.key) {
						return {
							...mItem,
							viewers: _.uniq([...(mItem?.viewers || []), requesterId]),
							// ? Why do I need to save description when handling approve?
							// description,
						};
					}
					if (mItem?.children && mItem?.children?.length !== 0) {
						const updatedChildren = mItem?.children?.map((child: any) =>
							traverse(child)
						);
						return { ...mItem, children: updatedChildren };
					}

					return mItem;
				};

				if (!root) return;
				let updatedRoot = { ...root };

				if (root.key === menuItem.key) {
					updatedRoot = {
						...root,
						viewers: root?.viewers
							? _.uniq([...root?.viewers, requesterId])
							: [requesterId],
					};
				}

				if (root?.children && root?.children?.length !== 0) {
					updatedRoot = { ...root, children: root?.children?.map(traverse) };
				}

				const updatedMenu = menu?.map((mItem: any) => {
					const { key } = mItem;
					const { key: rootKey } = updatedRoot;

					if (key === rootKey) return updatedRoot;
					return mItem;
				});

				await saveMenu(updatedMenu, menuItem.key);

				await sendSubmissionApproveEmail({
					component: menuItem?.component,
					component_title: menuItem?.title,
					redirect_url: `${window.location.origin}${window.location.pathname}`,
					requestor:
						mode === "testing"
							? testRecipients?.map((recipient: any) => recipient?.email)
							: [requestedUser?.email],
				});

				Emitter.emit("alert", {
					type: "success",
					message: "Dashboard configuration updated",
					description: "You have successfully updated dashboard configuration",
					timeout: 5000,
				});

				socket.emit("UPDATE_MENU", menuItem.key);
				socket.emit("ACCESS_REQUEST_STATUS_CHANGED", "BROADCAST");

				onApprove();
			} catch (err) {
				Emitter.emit("alert", {
					type: "error",
					message: "Error updating dashboard configuration",
					description:
						"There was an issue updating the dashboard configuration",
					timeout: 5000,
				});
				console.error(err);
			} finally {
				setLoading(false);
			}
		};
	};

	const handleReject = async (request: Partial<AccessRequest>) => {
		const { reason = "Not Specified" } = form.getFieldsValue();
		try {
			setLoading(true);

			const requestedUser = await getUser(request?.requesterId || "");
			await updateAccessRequest({ ...request, status: "Rejected", reason });
			await sendSubmissionRejectEmail({
				component: menuItem?.component,
				component_title: menuItem?.title,
				reason,
				redirect_url: `${window.location.origin}${window.location.pathname}`,
				requestor:
					mode === "testing"
						? testRecipients?.map((recipient: any) => recipient?.email)
						: [requestedUser?.email],
			});

			socket.emit("ACCESS_REQUEST_STATUS_CHANGED");
		} catch (err) {
			console.error(err);
		} finally {
			setLoading(false);
		}
	};

	const defaultColumns = [
		{
			title: "Request date",
			dataIndex: "date",
			key: "date",
			render: (something: any) => {
				return dayjs(something).format("DD-MM-YYYY hh:mm:ss a");
			},
		},
		{
			title: "Requested by",
			dataIndex: "requestedBy",
			key: "requestedBy",
			render: (value: any, record: any) => {
				return (
					<Space>
						<Text>{value}</Text>
						<Text>
							(<Link href={`mailto:${record.email}`}>{record.email}</Link>)
						</Text>
					</Space>
				);
			},
		},
		{ title: "Status", dataIndex: "status", key: "status" },
		{ title: "Modified by", dataIndex: "modifiedBy", key: "modifiedBy" },
		{ title: "Comment", dataIndex: "comment", key: "comment", width: 800 },
	];

	const columns = !readonly
		? [
				...defaultColumns,
				{
					title: "Actions",
					dataIndex: "action",
					key: "action",
					render: (_: any, record: any) => {
						const canAction =
							record?.status === "Requested" &&
							!users?.find((user: any) => user?.id === record?.requesterId) &&
							!loading;
						return (
							<Flex style={{ width: "100%" }} justify="space-between">
								<Link disabled={!canAction} onClick={handleApprove(record)}>
									Approve
								</Link>
								<Popconfirm
									overlayStyle={{ zIndex: 30003 }}
									okText="Done"
									onConfirm={() => handleReject(record)}
									title="Are you sure to reject this request?"
									description={
										<Form form={form} layout="vertical">
											<Form.Item name={"reason"} label="Reason">
												<Input placeholder="Reason" />
											</Form.Item>
										</Form>
									}
								>
									<Link disabled={!canAction}>Reject</Link>
								</Popconfirm>
							</Flex>
						);
					},
					width: 120,
				},
		  ]
		: defaultColumns;

	useEffect(() => {
		if (!requestsLoading) {
			setLoading(false);
		}
	}, [requestsLoading]);

	return accessRequests.length > 0 ? (
		<Row gutter={[16, 16]}>
			<Col span={24}>
				<Text style={{ fontSize: 16 }}>Requests</Text>
			</Col>
			<Col span={24}>
				<Table
					rootClassName="field-table"
					size="small"
					bordered
					columns={columns}
					dataSource={accessRequests}
					scroll={{ x: "max-content" }}
				/>
			</Col>
		</Row>
	) : (
		<Empty />
	);
};

export default AccessRequestTab;
