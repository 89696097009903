import { Button, Flex, Layout, Spin, Tooltip, Typography } from "antd";
import React, { useEffect, useState } from "react";
import RequestAccessScreen from "../components/RequestAccessScreen";
import usePermissions from "../hooks/usePermissions";
import ComponentManager from "../components/modal/ComponentManager";
import { AccessRequestProvider } from "../contexts/AccessRequestContext";
import RequestsBadge from "../components/RequestsBadge";
import {
	FullscreenExitOutlined,
	FullscreenOutlined,
	NotificationOutlined,
} from "@ant-design/icons";
import { useMainContext } from "../contexts/MainContext";
import useUrlQuery from "../hooks/useUrlQuery";
import useParameters from "../hooks/useParameters";
import { isDesktop } from "react-device-detect";
import { ComponentProvider } from "../contexts/ComponentContext";
import AnnouncementModal from "../components/modal/AnnouncementModal";

const { Text } = Typography;
const { Header } = Layout;

const withComponentManager = (Component: React.FC) => {
	return (props: any) => {
		const mitem = props.params.mitem;

		// Query Params for handling open configuration manager depending on query url
		const query = useUrlQuery();
		const {
			isAdmin,
			isOwner,
			isViewer,
			loading: permissionLoading,
		} = usePermissions(mitem);
		const { component_access, key, title = "" } = mitem;
		// const managedAccessMode =
		// 	!component_access || component_access !== "public";
		const hasAccess = isOwner || isViewer;
		const [open, setOpen] = useState(false);
		const [openAnnouncementModal, setOpenAnnouncementModal] = useState(false);
		const updatedProps = { ...props, isOwner, isViewer, isAdmin };

		const { fullScreen, toggleFullScreen } = useMainContext();

		// Parameters
		const { parameters } = useParameters(key);

		useEffect(() => {
			if (permissionLoading) {
				setOpen(false);
			} else {
				setOpen(isOwner ? (query.get("am") === "true" ? true : false) : false);
			}
		}, [permissionLoading, isOwner, query]);

		if (permissionLoading)
			return (
				<Flex
					justify="center"
					align="center"
					style={{ height: "100%", width: "100%" }}
				>
					<Spin />
				</Flex>
			);

		return (
			<ComponentProvider
				menuItem={mitem}
				isAdmin={isAdmin}
				isOwner={isOwner}
				isViewer={isViewer}
			>
				<AccessRequestProvider menuKey={key}>
					<Header style={{ padding: 16, border: "1px solid #112A45" }}>
						<Flex
							justify="space-between"
							align="center"
							style={{ flexWrap: "nowrap" }}
						>
							<Text
								style={{
									fontSize: 20,
									whiteSpace: "nowrap",
									overflow: "hidden",
									textOverflow: "ellipsis",
									maxWidth: "calc(100% - 200px)",
									flexShrink: 1,
								}}
							>
								{mitem.title}
							</Text>
							<Flex gap={8} align="center" style={{ flexWrap: "nowrap" }}>

								{hasAccess && (
									<RequestsBadge show={isOwner}>
										<Button onClick={() => setOpen(true)}>
											Component manager
										</Button>
									</RequestsBadge>
								)}

								{isOwner ? (
									<Tooltip title="Send Component Announcement">
										<Button
											onClick={() => setOpenAnnouncementModal(true)}
											icon={<NotificationOutlined />}
										/>
									</Tooltip>
								) : null}

								{hasAccess && isDesktop ? (
									<Tooltip
										title={fullScreen ? "Exit Fullscreen" : "Enter Fullscreen"}
									>
										<Button
											onClick={toggleFullScreen}
											icon={
												fullScreen ? (
													<FullscreenExitOutlined />
												) : (
													<FullscreenOutlined />
												)
											}
										/>
									</Tooltip>
								) : null}
							</Flex>
						</Flex>
					</Header>
					{!permissionLoading && (
						<>
							{hasAccess ? (
								<Component {...updatedProps} />
							) : (
								<RequestAccessScreen />
							)}
						</>
					)}

					<ComponentManager
						readonly={!isOwner}
						open={open}
						onCancel={() => setOpen(false)}
						onSave={() => setOpen(false)}
						parameters={parameters}
					/>
					<AnnouncementModal
						open={openAnnouncementModal}
						onCancel={() => setOpenAnnouncementModal(false)}
					/>
				</AccessRequestProvider>
			</ComponentProvider>
		);
	};
};

export default withComponentManager;
