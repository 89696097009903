import { Button, Input, Layout, Typography } from "antd";
import { useCallback, useContext, useMemo, useState } from "react";
import { GetAntIcon } from "../utils/ant_icons";
import VisualsList from "./VisualsList";
import { CustomDashboardContext } from "../contexts/context";
import RequestAccessModal from "./modal/RequestAccessModal";
import { findMeasureById, findMenuItemByKey } from "../utils/utils";
import { useCustomDashboard } from "../contexts/CustomDashboardContext";
import { useComponent } from "../contexts/ComponentContext";
import { useMenu } from "../contexts/MenuContextV2";

const { Header } = Layout;
const { Text } = Typography;

interface VisualTabProps {}

// this tab is responsible for all of its states
const VisualTab = ({}: VisualTabProps) => {
	// reducer
	const [searchVal, setSearchVal] = useState<string>("");
	const [showModal, setShowModal] = useState(false);
	const [requestMenuKey, setRequestMenuKey] = useState("");

	const { menu } = useMenu();

	// Context
	const {
		dispatch: customDashboardDispatch,
		state: customDashboardState,
		visualDispatch,
		visualState,
	} = useContext(CustomDashboardContext);
	const { isOwner } = useComponent();
	const { visuals, measures } = useCustomDashboard();
	const actionable = !customDashboardState.editMode;

	// CALLBACKS
	const createNewVisual = useCallback(() => {
		visualDispatch({ type: "NEW_VISUAL" });
	}, []);

	// Render
	const renderVisualButton = () => {
		if (!isOwner) return null;
		return (
			<Button
				disabled={!actionable}
				icon={GetAntIcon("plus")}
				onClick={createNewVisual}
			>
				New visual
			</Button>
		);
	};

	const onVisualDragStart = (e: any, visualId: string) => {
		customDashboardDispatch({
			type: "ON_VISUAL_DRAG_START",
			payload: visualId,
		});
	};

	const filteredVisuals = useMemo(() => {
		return visuals?.filter((_visual) => {
			return (
				_visual?.title?.toLowerCase().includes(searchVal.toLowerCase()) ||
				_visual?.subtitle?.toLowerCase().includes(searchVal.toLowerCase())
			);
		});
	}, [visuals, searchVal]);

	return (
		<>
			<Layout style={{ background: "transparent", height: "inherit" }}>
				<Header
					style={{
						padding: 0,
						background: "transparent",
						display: "flex",
						alignItems: "center",
						justifyContent: "space-between",
					}}
				>
					<Text style={{ fontSize: 24 }}>Visuals</Text>
					{renderVisualButton()}
				</Header>
				<Input
					placeholder="Search..."
					value={searchVal}
					onChange={(e) => setSearchVal(e.target.value)}
					allowClear
					style={{ borderRadius: 2, marginBottom: 16 }}
				/>
				<VisualsList
					draggable={customDashboardState.editMode}
					onVisualDragStart={onVisualDragStart}
					visuals={searchVal ? filteredVisuals : visuals}
					onRequest={(visual) => {
						const measure = findMeasureById(measures, visual.measure);
						if (measure) {
							setRequestMenuKey(measure.menuKey || "");
						}
					}}
				/>
			</Layout>
			<RequestAccessModal
				open={showModal}
				menuItem={findMenuItemByKey(menu, requestMenuKey)}
				onCancel={() => setShowModal(false)}
			/>
		</>
	);
};

export default VisualTab;
