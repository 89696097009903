import React, {
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useState,
} from "react";
import Plot from "react-plotly.js";
import {
	Button,
	Card,
	CardProps,
	Dropdown,
	Empty,
	Flex,
	MenuProps,
	Spin,
	Tag,
	Tooltip,
	Typography,
} from "antd";
import useSize2 from "../hooks/useSize2";
import { CustomDashboardContext } from "../contexts/context";
import { EditOutlined, LockOutlined } from "@ant-design/icons";
import VisualDeleteHandler from "./VisualDeleteHandler";
import CustomStatistic, { getTarget } from "./CustomStatistic";
import LabelVisual from "./LabelVisual";
import { VisualType } from "../types/Visual";
import { findMenuItemByKey, getColors, queryMeasure } from "../utils/utils";
import { useSelector } from "react-redux";
import { useComponent } from "../contexts/ComponentContext";
import { useCustomDashboard } from "../contexts/CustomDashboardContext";
import { useMenu } from "../contexts/MenuContextV2";

const { Link } = Typography;

type VisualProps = CardProps & {
	visual: VisualType;
	plotStyle?: React.CSSProperties;
	layoutId?: string;
	// showTarget?: boolean;
	showControls?: boolean;
	enableDrilldown?: boolean;
	onRequestAccess?: () => void;
	measure: MeasureType;
	onRefresh?: () => void;
	sliceValues?: Record<string, any>;
	staticMode?: boolean;
};

// * Visual is only responsible for displaying data
// Needs to know the mastered source for access request
const Visual = ({
	visual,
	plotStyle,
	layoutId,
	showControls,
	enableDrilldown,
	onRequestAccess,
	measure,
	onRefresh = () => {},
	sliceValues = {},
	staticMode = false,
	...restProps
}: VisualProps) => {
	const { height } = useSize2(`el-${layoutId}`);
	const {
		state: dashboardState,
		dispatch: dashboardDispatch,
		visualDispatch,
	} = useContext(CustomDashboardContext);
	const actionable = !dashboardState.editMode;

	const { menuItem, isOwner } = useComponent();
	const { loading, visualTags } = useCustomDashboard();
	const { menu } = useMenu();
	const currentUser = useSelector((state: any) => state.user);

	// CALLBACKS
	const handleEdit = useCallback(() => {
		visualDispatch({ type: "EDIT_VISUAL", payload: visual });
	}, [visual]);

	const dropdownMenu: MenuProps["items"] = useMemo(() => {
		return [
			{
				label: <Link onClick={handleEdit}>Edit</Link>,
				type: "group",
			},
			{
				label: <VisualDeleteHandler visual={visual} layoutId={layoutId} />,
				type: "group",
			},
		] as MenuProps["items"];

		return [];
	}, [layoutId, visual]);

	const [data, setData] = useState<any>(null);
	const [dataLoading, setDataLoading] = useState(false);

	// RENDER
	const renderBody = () => {
		if (!data) return null;
		if (!visual?.data?.value) return null;

		if ((data && data?.length === 0) || !visual?.data?.type) {
			return (
				<Flex
					justify="center"
					align="center"
					style={{ height: "100%", width: "100%", position: "relative" }}
				>
					<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
				</Flex>
			);
		}

		if (visual?.data?.type === "card") {
			const unitTargets = visual?.targets?.[dashboardState?.sliceValues?.unit];
			// to handle situations when user uses the predefined date ranges. (e.g day, month, week, quarter, year)
			return (
				<CustomStatistic
					visual={visual}
					valueObj={data?.[0]?.[visual?.data?.value]}
					targets={unitTargets}
					indicator_value={data?.[0]?.[visual?.data?.indicator_value]}
					target={getTarget(
						unitTargets?.target,
						visual?.target_multiplier || "",
						visual?.target_based_on || "",
						dashboardState?.sliceValues
					)}
					measure={measure}
				/>
				// <>{visual.title}</>
			);
		} else if (visual?.data?.type === "label") {
			// to handle situations when user uses the predefined date ranges. (e.g day, month, week, quarter, year)

			return (
				<LabelVisual
					label={visual?.data?.value}
					value={data[0][visual?.data?.value]}
				/>
			);
		} else if (visual?.data?.type === "image") {
			// to handle situations when user uses the predefined date ranges. (e.g day, month, week, quarter, year)

			return (
				<Flex style={{ height: "100%", width: "100%" }}>
					<img
						style={{ width: "100%", objectFit: "contain" }}
						src={data[0][visual?.data?.value]}
					/>
				</Flex>
			);
		} else {
			return (
				<Plot
					style={{
						width: "100%",
						// height: height - 48,
						...plotStyle,
					}}
					data={[
						{
							type: visual?.data?.type,
							x: data?.map((d: any) => d[visual?.data?.x]),
							y: data?.map((d: any) => d[visual?.data?.y]),
							values: data?.map((d: any) => d[visual?.data?.values]),
							labels: data?.map((d: any) => d[visual?.data?.labels]),
							hole: 0.7,
							mode: "gauge+number",
							textinfo: "none",
							value: data?.[0]?.[visual?.data?.value],
						},
					]}
					layout={{
						autosize: true,
						showlegend: false,
						plot_bgcolor: "rgb(17, 29, 44)",
						paper_bgcolor: "rgb(17, 29, 44)",
						// margin: staticPlot ? { l: 0, r: 0, t: 0, b: 0 } : {},
						margin: { l: 0, r: 0, t: 0, b: 0 },
						// legend: {
						// 	x: 0.5,
						// 	y: -0.3,
						// 	orientation: "h",
						// 	xanchor: "center",
						// 	yanchor: "bottom",
						// },
					}}
					config={{ staticPlot: true, displayModeBar: false }}
					useResizeHandler
				/>
				// <>Work In Progress</>
			);
		}
	};

	const getExtra = useMemo(() => {
		if (!isOwner) return null;

		if (showControls)
			return (
				<Dropdown disabled={!actionable} menu={{ items: dropdownMenu }}>
					<Link>
						<EditOutlined />
					</Link>
				</Dropdown>
			);
		return null;
	}, [isOwner, showControls, actionable, dropdownMenu]);

	const mastered = useMemo(
		() => findMenuItemByKey(menu, measure.menuKey || ""),
		[menu, measure.menuKey]
	);

	const loadData = useCallback(async () => {
		if (!mastered) return;

		setDataLoading(true);
		try {
			if (mastered) {
				const data = await queryMeasure(
					mastered,
					menuItem,
					measure,
					currentUser,
					isOwner,
					sliceValues,
					visual?.parameters
				);
				if (data?.response) {
					setData(data?.response);
				} else {
					setData(data);
				}
				setDataLoading(false);
			}
		} catch (error) {
			console.log(error);
		}
	}, [
		JSON.stringify(mastered),
		JSON.stringify(measure),
		JSON.stringify(menuItem),
		JSON.stringify(currentUser),
		JSON.stringify(isOwner),
		JSON.stringify(visual?.parameters),
		JSON.stringify(sliceValues),
	]);

	const visualTagsRecord = useMemo(() => {
		return visualTags.reduce<Record<string, VisualTag>>((acc, curr) => {
			acc[curr._id] = curr;

			return acc;
		}, {});
	}, [visualTags]);

	// Initial load
	useEffect(() => {
		// if (!visualLoaded || !staticMode)
		loadData();
	}, [loadData]);

	if (loading) return null;

	return (
		<Spin spinning={dataLoading} wrapperClassName={"generic-loading-wrapper"}>
			<Card
				{...restProps}
				title={
					<Tooltip placement="right" title={visual?.subtitle}>
						<Flex vertical gap={8}>
							{visual?.title}
							<Flex>
								{visual?.tags?.map((tag) =>
									visualTagsRecord[tag] ? (
										<Tag style={{ width: "fit-content" }} color="default">
											{visualTagsRecord[tag].label}
										</Tag>
									) : null
								)}
							</Flex>
						</Flex>
					</Tooltip>
				}
				extra={restProps.extra ?? getExtra}
				style={{
					borderRadius: 2,
					width: "100%",
					height: "100%",
					background: getColors(undefined).backgroundColor,
					borderColor: getColors(undefined).borderColor,
					...restProps.style,
				}}
				styles={{
					body: {
						height: "calc(100% - 59px)", // Subtracting 59 for the header
						display: "flex",
						position: "relative",
						padding: 0,
					},
					header: {
						padding: "12px 16px",
						background: getColors(undefined).headerColor,
						borderColor: getColors(undefined).borderColor,
						...restProps.styles?.header,
					},
				}}
				className="visual-card"
				classNames={{
					body:
						!enableDrilldown || !visual?.drilldown || data === "Unauthorized"
							? ""
							: "viz-body-clickable",
				}}
			>
				{data === "Unauthorized" ? (
					<Flex
						vertical
						align="center"
						justify="center"
						style={{
							height: "100%",
							width: "100%",
							position: "absolute",
							top: "50%",
							left: "50%",
							transform: `translate(-50%, -50%)`,
							zIndex: 2,
							backdropFilter: "blur(10px)",
						}}
						gap={12}
					>
						<LockOutlined style={{ fontSize: 32, opacity: 0.75 }} />
						Sorry, you don't have access{" "}
						<Button onClick={onRequestAccess}>Request access</Button>
					</Flex>
				) : null}
				<div
					style={{ height: "100%", width: "100%" }}
					onClick={() => {
						if (visual.drilldown && enableDrilldown)
							dashboardDispatch({
								type: "SHOW_DRILLDOWN",
								payload: visual,
							});
					}}
				>
					{renderBody()}
				</div>
			</Card>
		</Spin>
	);
};

export default Visual;
