import {
	Button,
	Empty,
	Flex,
	Input,
	Layout as AntdLayout,
	Popconfirm,
	Space,
	Table,
	Typography,
	Avatar,
} from "antd";
import { GetAntIcon } from "../utils/ant_icons";
import { useContext, useState } from "react";
import VisualGroupConfiguration from "./modal/VisualGroupsConfiguration";
import { ButtonProps } from "antd/lib";
import { deleteVisualGroup } from "../services/api-server/visualsGroups";
import { v4 as uuid } from "uuid";
import dayjs from "dayjs";
import { CustomDashboardContext } from "../contexts/context";
import Emitter from "../services/EventEmitter";
import { VisualGroupType } from "../types/VisualGroup";
import useDashboards from "../hooks/useDashboards";
import { CustomLayout } from "../types/CustomLayout";
import { VisualType } from "../types/Visual";
import { Layout } from "react-grid-layout";
import { updateVisual } from "../services/api-server/visuals";
import { useComponent } from "../contexts/ComponentContext";
import { useCustomDashboard } from "../contexts/CustomDashboardContext";

const { Text, Title, Link } = Typography;
const { Header } = AntdLayout;

const defaultColumns = [
	{ dataIndex: "name", key: "name", title: "Name" },
	{
		dataIndex: "colour",
		key: "colour",
		title: "Colour",
		render: (value: any) => (
			<Space>
				<Avatar size={"small"} shape="square" style={{ background: value }} />
				{value}
			</Space>
		),
	},
	{
		dataIndex: "updatedAt",
		key: "updatedAt",
		title: "Last updated",
		render: (value: any) => {
			if (value) return dayjs(value).format("YYYY-MM-DD HH:mm:ss");
		},
	},
	{ dataIndex: "createdBy", key: "createdBy", title: "Created by" },
];

const VisualGroupsTab = () => {
	const [searchVal, setSearchVal] = useState<string | undefined>("");
	const [openConfig, setOpenConfig] = useState(false);
	const { state: customDashboardState } = useContext(CustomDashboardContext);
	const [currentGroup, setCurrentGroup] = useState<Partial<VisualGroupType>>(
		{}
	);
	const { dashboards, saveDashboards } = useDashboards();
	const { menuItem, isOwner } = useComponent();
	const { visualGroups } = useCustomDashboard();
	const actionable = !customDashboardState.editMode;

	const createNewGroup = () => {
		setOpenConfig(true);
		const newOne = {
			id: uuid(),
			colour: "#164C7E",
			menuKey: menuItem.key,
		};
		setCurrentGroup(newOne);
	};

	const handleEdit = (group: VisualGroupType) => {
		return () => {
			setOpenConfig(true);
			setCurrentGroup(group);
		};
	};

	const handleDelete = async (group: VisualGroupType) => {
		if (dashboards?.length !== 0) {
			const layoutToRemove: string[] = []; // to be used to remove from visuals
			const updatedDashboards = dashboards?.map((_dashboard) => {
				const { dashboard_layout = [] } = _dashboard;
				const found = dashboard_layout?.find((_layout: CustomLayout) =>
					group?.layoutIds?.includes(_layout.i)
				);

				if (found) {
					const { children = [] } = found;

					customDashboardState?.visuals?.forEach((_visual: VisualType) => {
						const _found = children?.find((child: Layout) =>
							_visual?.layoutIds?.includes(child?.i)
						);

						if (_found) {
							layoutToRemove.push(_found.i);
						}
					});

					const updatedLayout = dashboard_layout?.filter(
						(_layout: CustomLayout) => _layout?.i !== found?.i
					);
					return { ..._dashboard, dashboard_layout: updatedLayout };
				}
				return { ..._dashboard };
			});

			try {
				// for loop to update the visual's layoutIds
				for (const _visual of customDashboardState?.visuals) {
					const found = _visual?.layoutIds?.find((_layoutId: string) =>
						layoutToRemove?.includes(_layoutId)
					);
					if (found) {
						const updatedVisual = {
							..._visual,
							layoutIds: _visual?.layoutIds?.filter(
								(_layoutId: string) => _layoutId !== found
							),
						};

						await updateVisual(updatedVisual, group.menuKey);
					}
				}
				await saveDashboards(updatedDashboards, group.menuKey);

				await deleteVisualGroup(group);
				Emitter.emit("alert", {
					type: "success",
					message: "Visual group deleted",
					description: "You have successfully deleted this visual group",
					timeout: 5000,
				});
				Emitter.emit("VISUAL_GROUPS_UPDATED", true);
			} catch (error) {
				Emitter.emit("alert", {
					type: "error",
					message: "Error deleting visual group",
					description: "An error occurred when deleting this visual group",
					timeout: 5000,
				});
				console.error(error);
			}
		}
	};

	const handleClose = () => {
		setOpenConfig(false);
		setCurrentGroup({});
	};

	const getDescription = (group: VisualGroupType) => {
		const { layoutIds = [] } = group;

		if (layoutIds?.length > 0) {
			const noun =
				layoutIds?.length === 1 ? "dashboard item" : "dashboard items";
			const conjuction = layoutIds?.length === 1 ? "is" : "are";
			return `There ${conjuction} ${layoutIds?.length} ${noun} using this visual group, deleting this visual group will delete relevant dashboard items`;
		}

		return null;
	};

	const columns = !isOwner
		? defaultColumns
		: [
				...defaultColumns,
				{
					dataIndex: "actions",
					key: "actions",
					title: "Actions",
					render: (_: any, record: any) => (
						<Space>
							<Link disabled={!actionable} onClick={handleEdit(record)}>
								Edit
							</Link>
							<Popconfirm
								title="Are you sure you want to delete this visual group?"
								description={getDescription(record)}
								onConfirm={() => handleDelete(record)}
							>
								<Link disabled={!actionable}>Delete</Link>
							</Popconfirm>
						</Space>
					),
				},
		  ];

	const getGroups = () => {
		return searchVal
			? visualGroups?.filter((_group) =>
					_group.name
						.toLowerCase()
						.trim()
						.includes(searchVal.toLowerCase().trim())
			  )
			: visualGroups;
	};

	return (
		<>
			<AntdLayout style={{ background: "transparent" }}>
				<Header
					style={{
						padding: 0,
						background: "transparent",
						display: "flex",
						alignItems: "center",
						justifyContent: "space-between",
					}}
				>
					<Text style={{ fontSize: 24 }}>Visual Groups</Text>
					<ConfigHandler disabled={!actionable} onClick={createNewGroup} />
				</Header>
				<Flex vertical gap={16}>
					<Input
						placeholder="Search..."
						onChange={(e) => setSearchVal(e.target.value)}
						value={searchVal}
						style={{ borderRadius: 2 }}
					/>
					{visualGroups.length === 0 ? (
						<Flex justify="center" align="center" style={{ height: "100%" }}>
							<Empty
								image={Empty.PRESENTED_IMAGE_SIMPLE}
								description={
									<Space direction="vertical" size={16}>
										<Title
											level={2}
											style={{
												margin: "16px 0 8px",
												fontWeight: "normal",
												color: "#FFFFFF73",
											}}
										>
											No groups here yet
										</Title>
										<Text style={{ color: "#FFFFFF73" }}>
											Create a new group to organise your visuals.
										</Text>
										<ConfigHandler
											disabled={!actionable}
											onClick={createNewGroup}
										/>
									</Space>
								}
							/>
						</Flex>
					) : (
						<Table
							size="small"
							bordered
							dataSource={getGroups()}
							columns={columns}
						/>
					)}
					<VisualGroupConfiguration
						visualGroup={currentGroup}
						open={openConfig}
						onCancel={handleClose}
						// onSave={fetchVisualGroups}
						onChange={setCurrentGroup}
					/>
				</Flex>
			</AntdLayout>
		</>
	);
};

const ConfigHandler = ({ ...restProps }: ButtonProps) => {
	const { isOwner } = useComponent();
	if (!isOwner) return null;
	return (
		<Button {...restProps} icon={GetAntIcon("plus")}>
			New group
		</Button>
	);
};

export default VisualGroupsTab;
