import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { socket } from "../utils/socket";
import { getUser } from "../services/api-server/usermgt";

const usePermissions = (menuItem: Record<string, any>) => {
	const [isAdmin, setIsAdmin] = useState(false);
	const [isOwner, setIsOwner] = useState(false);
	const [isViewer, setIsViewer] = useState(false);
	const [loading, setLoading] = useState(true);
	const {
		component_access,
		roles: menuRoles,
		viewers = [],
		owners = [],
	} = menuItem;
	const user = useSelector((state: any) => state.user);

	useEffect(() => {
		const checkPermission = () => {
			setIsOwner(false);
			setIsViewer(false);
			setLoading(true);

			getUser(user?.oid)
				.then((mongoUser: any) => {
					if (
						// ? Checking for Super Admin, component owner check #1
						user?.roles?.includes(process.env.REACT_APP_SUPER_ADMIN_TAG)
					) {
						setIsAdmin(true);
						setIsOwner(true);
					}
					// ! Discontinued due to legacy component roles management issues
					//  else if (
					// 	ownerRoles?.some((roleObj: any) =>
					// 		mongoUser?.roles?.find(
					// 			(roleId: string) => roleObj?.id === roleId
					// 		)
					// 	)
					// ) {
					// 	setIsOwner(true);
					// }
					// ? Component owner check #2 - User is an owner for the menu item
					else if (owners?.includes(user?.oid)) {
						setIsOwner(true);
					}
					// ? Viewer check #1 - User has the menu role
					else if (
						menuRoles?.find((role: string) => user?.roles?.includes(role))
					) {
						setIsViewer(true);
					}
					// ! Discontinued due to legacy component roles management issues
					// else if (
					// 	viewerRoles?.some((roleObj: any) =>
					// 		mongoUser?.roles?.find(
					// 			(roleId: string) => roleObj?.id === roleId
					// 		)
					// 	) ||
					// 	component_access === "public" || !component_access
					// ) {
					// 	setIsViewer(true);
					// }
					// ? Viewer check #2 - User is a viewer for the menu item
					else if (viewers?.includes(user?.oid)) {
						setIsViewer(true);
					}
					// ? Viewer check #3 - Menu item is general visibility
					else if (component_access === "public" || !component_access) {
						setIsViewer(true);
					} else {
						setIsOwner(false);
						setIsViewer(false);
						setIsAdmin(false);
					}
				})
				.catch((err) => {
					console.error(err);
				})
				.finally(() => {
					setLoading(false);
				});
		};

		checkPermission();

		socket.on("CONF_UPDATE", checkPermission);

		return () => {
			socket.off("CONF_UPDATE", checkPermission);
		};
	}, [menuItem, user, component_access, menuRoles]);

	return { isOwner, isViewer, isAdmin, loading };
};

export default usePermissions;
