import { useEffect, useState } from "react";
import { getTags } from "../services/api-server/visual_tags";
import Emitter from "../services/EventEmitter";

const useVisualTags = () => {
	const [visualTags, setVisualTags] = useState<VisualTag[]>([]);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		const loadVisualTags = async () => {
			setLoading(true);
			try {
				const data = await getTags();
				setVisualTags(data as VisualTag[]);
			} catch (error) {
				console.log(error);
			} finally {
				setLoading(false);
			}
		};

		loadVisualTags();

		Emitter.on("VISUAL_TAGS_UPDATED", loadVisualTags);

		return () => {
			Emitter.off("VISUAL_TAGS_UPDATED", loadVisualTags);
		};
	}, []);

	return { visualTags, loading };
};

export default useVisualTags;
