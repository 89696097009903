import { useCallback, useEffect, useState } from "react";
import Emitter from "../services/EventEmitter";
import { getMeasures } from "../services/api-server/measures";
import { findMeasureById } from "../utils/utils";

const useMeasures = () => {
	const [measures, setMeasures] = useState<MeasureType[]>([]);
	const [loading, setLoading] = useState(false);

	const getMeasureById = useCallback(
		(id: string) => findMeasureById(measures, id),
		[measures]
	);

	useEffect(() => {
		const updateMeasures = async () => {
			setLoading(true);

			try {
				const response = await getMeasures();
				setMeasures(response as MeasureType[]);
			} catch (error) {
				console.error(error);
			} finally {
				setLoading(false);
			}
		};

		// updates the measures during the first render
		updateMeasures();

		// attaching a listenerto update visuals
		Emitter.on("MEASURES_UPDATED", updateMeasures);

		// clean up
		return () => {
			Emitter.off("MEASURES_UPDATED", updateMeasures);
		};
	}, []);

	return { measures, loading, getMeasureById };
};

export default useMeasures;
