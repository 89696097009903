import {
	createContext,
	PropsWithChildren,
	useContext,
	useEffect,
	useState,
} from "react";
import { VisualType } from "../types/Visual";
import { VisualGroupType } from "../types/VisualGroup";
import useMeasures from "../hooks/useMeasures";
import useVisuals from "../hooks/useVisuals";
import useVisualGroups from "../hooks/useVisualGroups";
import { duckQuery } from "../services/api-server/deltashare";
import { socket } from "../utils/socket";
import useParameters from "../hooks/useParameters";
import { useComponent } from "./ComponentContext";
import useVisualTags from "../hooks/useVisualTags";

type CustomDashboardContextProps = {
	measures: MeasureType[];
	visuals: VisualType[];
	visualGroups: VisualGroupType[];
	parameters: Parameter[];
	paramValues: Record<string, any>;
	visualTags: VisualTag[];
	loading: boolean;
	progress: number;
};

const CustomDashboardContext = createContext<
	CustomDashboardContextProps | undefined
>(undefined);

type CustomDashboardProviderProps = PropsWithChildren & {};

const CustomDashboardProvider = ({
	children,
}: CustomDashboardProviderProps) => {
	const [progress, setProgress] = useState(0);
	const [loading, setLoading] = useState(true);
	const [initialLoaded, setInitialLoaded] = useState(false);
	const [dataLoading, setDataLoading] = useState(true);
	const { measures, loading: measuresLoading } = useMeasures();
	const { menuItem } = useComponent();
	const { visuals, loading: visualsLoading } = useVisuals(menuItem.key);
	const { visualTags, loading: visualTagsLoading } = useVisualTags();
	const { visualGroups, loading: visualGroupsLoading } = useVisualGroups(
		menuItem.key
	);
	const {
		loading: paramsLoading,
		parameters,
		paramValues,
	} = useParameters(menuItem.key);

	useEffect(() => {
		if (!initialLoaded) {
			if (
				!measuresLoading &&
				!visualsLoading &&
				!visualGroupsLoading &&
				!dataLoading &&
				!paramsLoading &&
				!visualTagsLoading
			) {
				setInitialLoaded(true);
				setLoading(false);
			} else {
				setLoading(true);
			}
		}
	}, [
		measuresLoading,
		visualsLoading,
		visualGroupsLoading,
		dataLoading,
		paramsLoading,
		initialLoaded,
		visualTagsLoading,
	]);

	useEffect(() => {
		const getDataFetchProgress = () => {
			duckQuery("SHOW TABLES", [], true)
				.then((data: any) => {
					if (data?.response?.length !== 0) {
						setDataLoading(false);
					} else {
						setDataLoading(true);
					}
				})
				.catch((error) => {
					// Emitter.emit("alert", {
					// 	type: "error",
					// 	message: "Unsuccessful query",
					// 	description: "Please refresh the page or try again later.",
					// });
					console.error(error);
				});
		};

		const handleLoading = (data: Record<string, any>) => {
			const { total = 0, completed = 0 } = data;
			setProgress(Math.round((completed / total) * 100));

			if (total === completed) {
				setDataLoading(true);
			} else {
				setDataLoading(false);
			}
		};

		getDataFetchProgress();

		socket.on("DATA_LOADING", (data) => handleLoading(data));

		return () => {
			socket.off("DATA_LOADING", (data) => handleLoading(data));
		};
	}, []);

	return (
		<CustomDashboardContext.Provider
			value={{
				loading,
				measures,
				visuals,
				visualGroups,
				paramValues,
				parameters,
				visualTags,
				progress,
			}}
		>
			{children}
		</CustomDashboardContext.Provider>
	);
};

const useCustomDashboard = () => {
	const context = useContext(CustomDashboardContext);

	if (!context) {
		throw new Error(
			"useCustomDashboard must be used within a CustomDashboardProvider"
		);
	}

	return context;
};

export { CustomDashboardProvider, useCustomDashboard };
