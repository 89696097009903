import { Form, Select } from "antd";
import { useContext, useMemo } from "react";
import { capitalize } from "../utils/dataTools";
import { CustomDashboardContext } from "../contexts/context";

interface PlotInputsProps {
	formValues?: any;
}

const PlotInputs = ({ formValues }: PlotInputsProps) => {
	// CONTEXT
	const { visualState } = useContext(CustomDashboardContext);

	// MEMOS
	const getFieldOptions = useMemo(() => {
		return visualState.fields.map((field: any) => ({
			key: field,
			label: field,
			value: field,
		}));
	}, [visualState.fields]);

	const getTypeOptions = useMemo(() => {
		return [
			// "bar", "scatter", "pie", "indicator",
			"card",
			"label",
			"image",
		].map((type) => ({
			key: type,
			label: capitalize(type),
			value: type,
		}));
	}, []);

	const getDisplayOptions = useMemo(() => {
		return [
			// "bar", "scatter", "pie", "indicator",
			"simple",
			"default",
		].map((type) => ({
			key: type,
			label: capitalize(type),
			value: type,
		}));
	}, []);

	const renderInputs = useMemo(() => {
		switch (formValues?.data?.type) {
			case "scatter":
			case "bar":
				return (
					<>
						<Form.Item label="Y-axis" name={["data", "y"]}>
							<Select
								dropdownStyle={{ zIndex: 30003 }}
								placeholder={"Search to select..."}
								options={getFieldOptions}
							/>
						</Form.Item>
						<Form.Item label="X-axis" name={["data", "x"]}>
							<Select
								dropdownStyle={{ zIndex: 30003 }}
								placeholder={"Search to select..."}
								options={getFieldOptions}
							/>
						</Form.Item>
					</>
				);
			case "label":
				return (
					<>
						<Form.Item label="Display" name={"display"}>
							<Select options={getDisplayOptions} placeholder={"Display"} />
						</Form.Item>
					</>
				);

			case "pie":
				return (
					<>
						<Form.Item label="Labels" name={["data", "labels"]}>
							<Select
								options={getFieldOptions}
								placeholder={"Search to select..."}
							/>
						</Form.Item>
						<Form.Item label="Values" name={["data", "values"]}>
							<Select
								options={getFieldOptions}
								placeholder={"Search to select..."}
							/>
						</Form.Item>
					</>
				);
			case "card":
			case "indicator":
				return <></>;
			default:
				return <></>;
		}
	}, [visualState.visual, getFieldOptions, formValues?.data?.type]);

	return (
		<>
			<Form.Item label="Type" name={["data", "type"]}>
				<Select options={getTypeOptions} placeholder={"Type"} />
			</Form.Item>
			{visualState?.visual?.data?.type ? <>{renderInputs}</> : null}
		</>
	);
};

export default PlotInputs;
