import React, { useCallback, useContext } from "react";
import Visual from "./Visual";
import StaggeredGrid from "./StaggeredGrid";
import { Empty, Space, Typography } from "antd";
import { Responsive, WidthProvider } from "react-grid-layout";
import { VisualType } from "../types/Visual";
import { useCustomDashboard } from "../contexts/CustomDashboardContext";
import { findMeasureById } from "../utils/utils";
import { CustomDashboardContext } from "../contexts/context";
const { Text, Title } = Typography;

const ResponsiveGridLayout = WidthProvider(Responsive);

type VisualsListProps = {
	draggable?: boolean;
	onVisualDragStart?: (
		e: React.DragEvent<HTMLDivElement>,
		visualId: any
	) => void;
	visuals?: VisualType[];
	onRequest?: (visual: VisualType) => void;
};

const VisualsList = ({
	draggable = false,
	onVisualDragStart = () => {},
	visuals = [],
	onRequest = () => {},
}: VisualsListProps) => {
	const { state: customDashboardState } = useContext(CustomDashboardContext);
	const { measures } = useCustomDashboard();
	const onDragStart = useCallback(
		(e: React.DragEvent<HTMLDivElement>, visualId: string) => {
			onVisualDragStart(e, visualId);
		},
		[]
	);

	if (visuals?.length === 0)
		return (
			<Empty
				image={Empty.PRESENTED_IMAGE_SIMPLE}
				description={
					<Space direction="vertical" size={16}>
						<Title
							level={2}
							style={{
								margin: "16px 0 8px",
								fontWeight: "normal",
								color: "#FFFFFF73",
							}}
						>
							No visuals here yet
						</Title>
						<Text style={{ color: "#FFFFFF73" }}>
							Create a new visual to update your dashboard.
						</Text>
					</Space>
				}
			/>
		);

	// const generateLayout = () => {
	// 	const itemsPerRow = 3;
	// 	const baseHeight = 3;
	// 	const baseWidth = 2;

	// 	return visuals?.map((visual, i) => {
	// 		return {
	// 			i: visual.id,
	// 			h: baseHeight,
	// 			w: baseWidth,
	// 			x: (i % itemsPerRow) * baseWidth,
	// 			y: Math.floor(i / itemsPerRow) * baseHeight,
	// 		};
	// 	});
	// };

	return (
		<>
			<StaggeredGrid
				items={visuals.map((visual) => {
					const measure = findMeasureById(measures, visual.measure);
					if (measure)
						return (
							<div
								className="droppable-element"
								// draggable={false}
								draggable={draggable}
								onDragStart={(e) => {
									onDragStart(e, visual.id);
									e.dataTransfer.setData("visual_id", visual.id);
								}}
							>
								<Visual
									key={visual.id}
									visual={visual}
									plotStyle={{ maxHeight: 250 }}
									// params={{ ...measure?.params, ...visual?.parameters }}
									showControls
									onRequestAccess={() => {
										onRequest(visual);
									}}
									measure={measure}
									sliceValues={customDashboardState.initialSliceValues}
									staticMode
								/>
								{/* {JSON.stringify(data[visual.id])} */}
							</div>
						);
					return null;
				})}
			/>
			{/* //! Commented for now, for further development */}
			{/* <ResponsiveGridLayout
				useCSSTransforms={false}
				isDraggable={false}
				isResizable={false}
				isDroppable={false}
				layouts={{ lg: generateLayout() }}
				rowHeight={80}
				breakpoints={{ lg: 1200, md: 768, sm: 576 }}
				cols={{ lg: 6, md: 3, sm: 1 }}
				containerPadding={[0, 0]}
			>
				{visuals.map((visual, i) => {
					return (
						<div
							key={visual.id}
							className="droppable-element"
							// draggable={false}
							draggable={draggable}
							onDragStart={(e) => {
								onDragStart(e, visual.id);
								e.dataTransfer.setData("visual_id", visual.id);
							}}
						>
							<Visual
								data={visualData[visual.id]}
								key={visual.id}
								visual={visual}
								plotStyle={{ maxHeight: 250 }}
								showControls
							/>
						</div>
					);
				})}
			</ResponsiveGridLayout> */}
		</>
	);
};

export default VisualsList;
