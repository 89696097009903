import {
	Button,
	Card,
	CardProps,
	Dropdown,
	Flex,
	MenuProps,
	Space,
} from "antd";
import { CustomLayout } from "../types/CustomLayout";
import DashboardItemContainer from "./DashboardItemContainer";
import { EditOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { useContext, useMemo } from "react";
import { CustomDashboardContext } from "../contexts/context";
import { Layout, Layouts } from "react-grid-layout";
import { v4 as uuid } from "uuid";
import { findMeasureById, getColors } from "../utils/utils";
import { DRAWER_KEYS } from "../reducers/customDashboardReducer";
import VisualActionsDropdown from "./VisualActionsDropdown";
import Visual from "./Visual";
import { useCustomDashboard } from "../contexts/CustomDashboardContext";

type DashboardItemProps = CardProps & {
	item: CustomLayout;
	onLayoutChange?: (layout: CustomLayout) => void;
};

const DashboardItem = ({
	item,
	onLayoutChange = () => {},
	...restProps
}: DashboardItemProps) => {
	const { dispatch: customDashboardDispatch, state: customDashboardState } =
		useContext(CustomDashboardContext);
	const { children = [] } = item;
	const { measures } = useCustomDashboard();

	// ? This is only applicable for group
	const group = customDashboardState.groups?.find((_group) =>
		_group?.layoutIds?.find((layoutId: string) => layoutId === item?.i)
	);

	const visual = customDashboardState?.visuals.find((_visual) =>
		_visual?.layoutIds?.find(
			(layoutId: string) => layoutId === item?.children?.[0]?.i
		)
	);

	const onChange = (layout: Layout[], allLayouts: Layouts) => {
		const updateLayout: CustomLayout[] = customDashboardState.layout.map(
			(_layout) => {
				if (_layout.i === item.i) {
					return { ..._layout, children: layout, layouts: allLayouts };
				}
				return _layout;
			}
		);

		customDashboardDispatch({
			type: "UPDATE_LAYOUT",
			payload: updateLayout,
		});
	};

	const handleRemoveLayout = () => {
		customDashboardDispatch({ type: "REMOVE_LAYOUT", payload: item });
	};

	const handleAddGroup = (group: any) => {
		return () => {
			const { layoutIds = [] } = group;

			const destinationLayout = customDashboardState?.layout?.find(
				(_item: any) => layoutIds.includes(_item.i)
			);

			const destinationId = destinationLayout?.i || uuid();

			customDashboardDispatch({
				type: "ADD_TO_GROUP",
				payload: {
					source: { id: item.i },
					destination: { id: destinationId },
					group,
				},
			});
		};
	};

	const defaultItems: MenuProps["items"] = useMemo(() => {
		return [
			{
				key: "delete",
				label: "Remove",
				onClick: handleRemoveLayout,
			},
		];
	}, []);

	const items: MenuProps["items"] =
		item.isGroup || customDashboardState.groups?.length === 0
			? defaultItems
			: [
					...defaultItems,
					{
						key: "add_group",
						label: "Add to group",
						children: customDashboardState.groups?.map((child: any) => ({
							key: child.id,
							label: child.name,
							onClick: handleAddGroup(child),
						})),
					},
			  ];

	// const title = item.add ? null : item.isGroup ? group?.name : visual?.title;

	const cardStyles: CardProps = {
		style: item.isGroup
			? {
					background: getColors(group?.colour).backgroundColor,
					borderColor: getColors(group?.colour).borderColor,
			  }
			: {
					background: getColors(undefined).backgroundColor,
					borderColor: getColors(undefined).borderColor,
			  },
		styles: {
			body: {
				background: getColors(group?.colour).innerBackgroundColor,
			},
			header: item.isGroup
				? {
						background: getColors(group?.colour).headerColor,
						borderColor: getColors(group?.colour).borderColor,
				  }
				: {
						background: getColors(undefined).headerColor,
						borderColor: getColors(undefined).borderColor,
				  },
		},
	};

	const openVisualModal = () => {
		customDashboardDispatch({ type: "DRAWER", payload: DRAWER_KEYS.VISUALS });
	};

	const measure = useMemo(
		() => findMeasureById(measures, visual?.measure || ""),
		[measures, visual?.measure]
	);

	if (item.add) {
		return (
			<Button
				onClick={openVisualModal}
				style={{ height: "100%", width: "100%", ...cardStyles.style }}
				type="link"
			>
				<Flex
					style={{ height: "inherit", width: "inherit" }}
					align="center"
					justify="center"
					vertical
					gap={8}
				>
					<PlusCircleOutlined style={{ fontSize: 32 }} />
					Add Item
				</Flex>
			</Button>
		);
	}

	if (item.isGroup) {
		return (
			<Card
				title={group?.name}
				key={item.i}
				style={{
					borderRadius: 2,
					width: "100%",
					...cardStyles.style,
				}}
				styles={{
					body: { padding: 0 },
					header: {
						padding: "16px 24px",
						...cardStyles.styles?.header,
					},
				}}
				extra={
					<Space>
						{item.add ? null : customDashboardState.editMode ? (
							<Dropdown
								trigger={["click"]}
								menu={{
									items,
									rootClassName: "draggableCancel",
								}}
							>
								<EditOutlined />
							</Dropdown>
						) : null}
					</Space>
				}
			>
				<DashboardItemContainer
					item={item}
					layout={children}
					onLayoutChange={onChange}
					isGroup={item.isGroup}
					style={cardStyles.style}
					styles={{
						header: {
							...cardStyles.styles?.header,
						},
					}}
				/>
			</Card>
		);
	}

	if (measure && visual) {
		return (
			<Visual
				enableDrilldown
				layoutId={children[0].i}
				key={children[0].i}
				visual={visual}
				measure={measure}
				style={cardStyles.style}
				styles={{ header: { ...cardStyles.styles?.header } }}
				sliceValues={customDashboardState.currentSliceValues}
				extra={
					<Space>
						<VisualActionsDropdown visual={visual} />
						{item.add ? null : customDashboardState.editMode ? (
							<Dropdown
								trigger={["click"]}
								menu={{
									items,
									rootClassName: "draggableCancel custom-dropdown",
								}}
							>
								<EditOutlined />
							</Dropdown>
						) : null}
					</Space>
				}
			/>
		);
	}

	return null;
};

export default DashboardItem;
