import { CardProps, Dropdown, Space } from "antd";
import { useContext, useEffect, useMemo, useRef } from "react";
import { Layout, Layouts, Responsive, WidthProvider } from "react-grid-layout";
import { CustomDashboardContext } from "../contexts/context";
import { EditOutlined } from "@ant-design/icons";
import { VisualType } from "../types/Visual";
import { CONTAINER_PADDING } from "../reducers/customDashboardReducer";
import useResizeObserver from "../hooks/useResizeObserver";
import VisualActionsDropdown from "./VisualActionsDropdown";
import Visual from "./Visual";
import { useCustomDashboard } from "../contexts/CustomDashboardContext";
import { findMeasureById } from "../utils/utils";

type DashboardItemContainerProps = CardProps & {
	item: Layout & { layouts?: Layouts };
	onLayoutChange?: (layout: Layout[], allLayouts: Layouts) => void;
	layout: Layout[];
	isGroup?: boolean;
};

let timeout: any = null;

const DashboardItemContainer = ({
	item,
	layout,
	onLayoutChange = () => {},
	isGroup,
	...restProps
}: DashboardItemContainerProps) => {
	const wrapperRef = useRef(null);
	const dimensions = useResizeObserver(wrapperRef);
	const { measures } = useCustomDashboard();

	const ResponsiveGridLayout = useMemo(
		() => WidthProvider(Responsive),
		[item.w]
	);

	const { state: customDashboardState, dispatch: customDashboardDispatch } =
		useContext(CustomDashboardContext);

	// ? Only applicable to visuals that are in groups
	const handleMove = (layout: Layout, visual: VisualType) => {
		return () => {
			customDashboardDispatch({
				type: "MOVE_VISUAL_TO_MAIN",
				payload: { layout, visual },
			});
		};
	};

	const handleDelete = (layout: Layout, visual: VisualType) => {
		return () => {
			customDashboardDispatch({
				type: "DELETE_VISUAL_FROM_GROUP",
				payload: { layout, visual },
			});
		};
	};

	useEffect(() => {
		if (!customDashboardState.isResize && !customDashboardState.isDrag) {
			timeout = setTimeout(() => {
				customDashboardDispatch({
					type: "RESIZE_GROUP",
					payload: { item, dimensions },
				});
			}, 100);
		}

		return () => {
			clearTimeout(timeout);
		};
	}, [dimensions, customDashboardState.isResize, customDashboardState.isDrag]);

	return (
		<div ref={wrapperRef} id={`inner-layout-wrapper-${item.i}`}>
			<ResponsiveGridLayout
				className={`inner-layout-${item.i}`}
				style={{ background: "green", height: "100%" }}
				layouts={item.layouts}
				onDragStart={(layout, oldItem, newItem, placeholder, e) => {
					e.stopPropagation();
					customDashboardDispatch({ type: "IS_DRAG", payload: true });
				}}
				onDragStop={() => {
					customDashboardDispatch({ type: "IS_DRAG", payload: false });
				}}
				onLayoutChange={onLayoutChange}
				isDraggable={customDashboardState.editMode}
				isResizable={customDashboardState.editMode}
				rowHeight={80}
				containerPadding={[CONTAINER_PADDING, CONTAINER_PADDING]}
				breakpoints={{ lg: 800, md: 400, sm: 200 }}
				cols={{ lg: 3, md: 2, sm: 1 }}
				draggableCancel=".draggableCancel"
			>
				{layout?.map((child) => {
					const visual = customDashboardState.visuals.find((_visual) =>
						_visual?.layoutIds?.find((layoutId: string) => layoutId === child.i)
					);

					const measure = findMeasureById(measures, visual?.measure || "");

					if (visual && measure)
						return (
							<div key={child.i}>
								<Visual
									enableDrilldown
									layoutId={item.i}
									key={visual.id}
									visual={visual}
									measure={measure}
									plotStyle={{ maxHeight: 250 }}
									style={restProps.style}
									styles={restProps.styles}
									sliceValues={customDashboardState.currentSliceValues}
									extra={
										<Space>
											<VisualActionsDropdown visual={visual} />
											{customDashboardState.editMode && visual ? (
												<Dropdown
													trigger={["click"]}
													menu={{
														rootClassName: "draggableCancel",
														items: [
															{
																key: "delete",
																label: "Remove",
																onClick: handleDelete(child, visual),
															},
															{
																key: "move",
																label: "Move to Main Layout",
																onClick: handleMove(child, visual),
															},
														],
													}}
												>
													<EditOutlined />
												</Dropdown>
											) : null}
										</Space>
									}
								/>
							</div>
						);

					return null;
				})}
			</ResponsiveGridLayout>
		</div>
	);
};

export default DashboardItemContainer;
