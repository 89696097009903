import {
	createContext,
	PropsWithChildren,
	useContext,
	useEffect,
	useState,
} from "react";
import { loadMenu } from "../services/api-server/menu";
import { socket } from "../utils/socket";

type MenuContextV2Props = {
	menu: Record<string, any>[];
};

const MenuContextV2 = createContext<MenuContextV2Props | undefined>(undefined);

const MenuProvider = ({ children }: PropsWithChildren) => {
	const [menu, setMenu] = useState<Record<string, any>[]>([]);

	useEffect(() => {
		const fetchMenu = async () => {
			try {
				const data = await loadMenu();
				if (Array.isArray(data.menu)) {
					setMenu(data.menu);
				}
			} catch (error) {
				setMenu([]);
				console.log(error);
			}
		};

		fetchMenu();

		socket.on("MENURESTART", fetchMenu);

		return () => {
			socket.off("MENURESTART", fetchMenu);
		};
	}, []);
	return (
		<MenuContextV2.Provider value={{ menu }}>{children}</MenuContextV2.Provider>
	);
};

const useMenu = () => {
	const context = useContext(MenuContextV2);

	if (!context) throw new Error("useMenu must be used within a MenuProvider");

	return context;
};

export { MenuProvider, useMenu };
