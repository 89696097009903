import { Button, Input, Layout, Popconfirm, Typography } from "antd";
import { useContext, useMemo, useState } from "react";
import MeasureList from "./MeasureList";
import { GetAntIcon } from "../utils/ant_icons";
import { CustomDashboardContext } from "../contexts/context";
import { useCustomDashboard } from "../contexts/CustomDashboardContext";
import { useComponent } from "../contexts/ComponentContext";

const { Header } = Layout;
const { Text } = Typography;

interface MeasureContentProps {
	menuitem: any;
}

const MeasureTab = ({ menuitem }: MeasureContentProps) => {
	const [searchVal, setSearchVal] = useState<string | undefined>("");

	const {
		state: customDashboardState,
		measureDispatch,
		measureState,
	} = useContext(CustomDashboardContext);
	const { menuItem, isOwner } = useComponent();
	const { measures } = useCustomDashboard();
	const actionable = !customDashboardState.editMode;

	const handleCreateMeasure = () => {
		localStorage.removeItem(`menu.${menuItem.key}`);
		measureDispatch({ type: "CREATE_MEASURE" });
	};

	const handleRestoreMeasure = (measure: Partial<MeasureType>) => {
		measureDispatch({ type: "RESTORE_MEASURE", payload: measure });
	};

	const renderwMeasureButton = () => {
		if (!isOwner || menuitem?.component != "Custom Dashboard") return null;

		const hasPrevious = localStorage?.getItem(`menu.${menuItem.key}`);

		if (hasPrevious) {
			const measure = JSON.parse(hasPrevious);
			return (
				<Popconfirm
					title="Unsaved measure"
					description="You have unsaved changes. Would you like to restore them or create a new measure?"
					okText="Restore"
					okButtonProps={{ type: "primary" }}
					cancelText="Create New"
					onCancel={handleCreateMeasure}
					disabled={!actionable}
					onConfirm={() => handleRestoreMeasure(measure)}
				>
					<Button icon={GetAntIcon("plus")}>New measure</Button>
				</Popconfirm>
			);
		}
		return (
			<Button onClick={handleCreateMeasure} icon={GetAntIcon("plus")}>
				New measure
			</Button>
		);
	};

	const sortedByMasteredMeasures = useMemo(() => {
		const sortedMeasures = measures?.reduce<{
			mastered: MeasureType[];
			rest: MeasureType[];
		}>(
			(acc, curr) => {
				if (curr.menuKey === menuItem.key) {
					acc.mastered.push(curr);
				} else {
					acc.rest.push(curr);
				}

				return acc;
				// handle measures that are does not have the menuKey as the current menu item
			},
			{ mastered: [], rest: [] }
		);

		const sortByIncId = (a: MeasureType, b: MeasureType) => {
			return parseInt(b.incId) - parseInt(a.incId);
		};

		// const sortByLastUpdate = (a: MeasureType, b: MeasureType) => {
		// 	if (dayjs(a?.lastUpdatedAt).isAfter(b?.lastUpdatedAt)) return -1;
		// 	return 1;
		// };

		return [
			...sortedMeasures.mastered.sort(sortByIncId),
			...sortedMeasures.rest.sort(sortByIncId),
		];
	}, [measures, menuItem]);

	console.log(sortedByMasteredMeasures);

	const filteredMeasures = useMemo(() => {
		if (!searchVal) return [];
		return sortedByMasteredMeasures?.filter((_measure) => {
			return (
				_measure?.name?.toLowerCase()?.includes(searchVal?.toLowerCase()) ||
				_measure?.description?.toLowerCase()?.includes(searchVal?.toLowerCase())
			);
		});
	}, [searchVal, sortedByMasteredMeasures]);

	return (
		<>
			<Layout style={{ background: "transparent", height: "inherit" }}>
				<Header
					style={{
						padding: 0,
						background: "transparent",
						display: "flex",
						alignItems: "center",
						justifyContent: "space-between",
					}}
				>
					<Text style={{ fontSize: 24 }}>Measures</Text>
					{renderwMeasureButton()}
				</Header>
				<Input
					placeholder="Search..."
					onChange={(e) => setSearchVal(e.target.value)}
					value={searchVal}
					style={{ borderRadius: 2, marginBottom: 16 }}
					allowClear
				/>
				<MeasureList
					measures={searchVal ? filteredMeasures : sortedByMasteredMeasures}
					state={measureState}
					dispatch={measureDispatch}
				/>
			</Layout>
		</>
	);
};

export default MeasureTab;
