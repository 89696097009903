import { useCallback, useEffect, useState } from "react";
import { getVisuals } from "../services/api-server/visuals";
import Emitter from "../services/EventEmitter";
import { findVisualById } from "../utils/utils";
import { VisualType } from "../types/Visual";

const useVisuals = (menuKey: any) => {
	const [visuals, setVisuals] = useState<VisualType[]>([]);
	const [loading, setLoading] = useState(false);

	const updateVisuals = useCallback(() => {
		setLoading(true);

		getVisuals(menuKey)
			.then((data: any) => {
				setVisuals(data);
			})
			.catch((error) => {
				console.error(error);
			})
			.finally(() => {
				setLoading(false);
			});
	}, [menuKey]);

	const getVisualById = (id: string) => findVisualById(visuals, id);

	useEffect(() => {
		// updates the visuals during the first render
		updateVisuals();

		// attaching a listener to update visuals
		Emitter.on("VISUALS_UPDATED", updateVisuals);

		// clean up
		return () => {
			Emitter.off("VISUALS_UPDATED", updateVisuals);
		};
	}, []);

	return { loading, visuals, getVisualById };
};

export default useVisuals;
