import { useCallback, useEffect, useState } from "react";
import { listVisualGroups } from "../services/api-server/visualsGroups";
import Emitter from "../services/EventEmitter";
import { VisualGroupType } from "../types/VisualGroup";

const useVisualGroups = (menuKey: string) => {
	const [visualGroups, setVisualGroups] = useState<VisualGroupType[]>([]);
	const [loading, setLoading] = useState(false);

	const updateVisualGroups = useCallback(() => {
		setLoading(true);

		listVisualGroups()
			.then((data: any) => {
				if (Array.isArray(data))
					setVisualGroups(
						data?.filter((_group) => _group?.menuKey === menuKey) || []
					);
			})
			.catch((err) => {
				console.log(err);
			})
			.finally(() => {
				setLoading(false);
			});
	}, []);

	useEffect(() => {
		// updates the visual groups during the first render
		updateVisualGroups();

		// attaching a listener to update visuals
		Emitter.on("VISUAL_GROUPS_UPDATED", updateVisualGroups);

		// clean up
		return () => {
			Emitter.off("VISUAL_GROUPS_UPDATED", updateVisualGroups);
		};
	}, []);

	return { loading, visualGroups };
};

export default useVisualGroups;
