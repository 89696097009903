import { createContext, Dispatch } from "react";
import {
	CustomDashboardAction,
	CustomDashboardState,
	initialState,
} from "../reducers/customDashboardReducer";
import {
	INIITAL_STATE,
	VisualAction,
	VisualState,
} from "../reducers/visualReducer";
import { INITIALSTATE } from "../reducers/measureReducer";

/**
 * context values to be stored and used in other areas.
 * makes sure everything that's needed are loaded before dashboard
 * functionalities (measure creation, visual creation) are ready to be used
 */

export const CustomDashboardContext = createContext<{
	state: CustomDashboardState;
	dispatch: any;
	metadataLoading: boolean;
	tables: Array<any>;
	measureState: any;
	measureDispatch: Dispatch<any>;
	visualState: VisualState;
	visualDispatch: Dispatch<VisualAction>;
}>({
	state: initialState,
	dispatch: (action: CustomDashboardAction) => {},
	metadataLoading: false,
	tables: [],
	measureState: {},
	measureDispatch: () => {},
	visualState: INIITAL_STATE,
	visualDispatch: (action: VisualAction) => {},
});

/**
 * storing measure states and dispatch to prevent props drilling
 */
export const MeasureContext = createContext({
	state: INITIALSTATE,
	dispatch: (action: any) => {},
});
