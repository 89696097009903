import { useCallback, useContext, useEffect, useState } from "react";
import {
	ExclamationCircleOutlined,
	RedoOutlined,
	SafetyCertificateOutlined,
} from "@ant-design/icons";
import {
	Badge,
	Button,
	Flex,
	Form,
	Modal,
	ModalProps,
	Popconfirm,
	Result,
	Space,
	Spin,
	Switch,
	Tooltip,
	Typography,
	Tabs,
} from "antd";
import { ACTIONTYPES, PAGE_TYPE } from "../../reducers/measureReducer";
import {
	duckQuery,
	getAllTables,
	getShares,
} from "../../services/api-server/deltashare";
import "react-quill/dist/quill.snow.css";
import PreviewTable from "../PreviewTable";
import StatusHandler from "../StatusHandler";
import { generateCompletions, getErrorMessage } from "../../utils/queryBuilder";
import NameDescriptionModal from "./NameDescriptionModal";
import QueryParameters from "../QueryParameters";
import CustomEditor from "../CustomEditor";
import { CustomDashboardContext } from "../../contexts/context";
import DataExplorer from "../DataExplorer";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { useCustomDashboard } from "../../contexts/CustomDashboardContext";
import { findMenuItemByKey, queryMeasure } from "../../utils/utils";
import { useComponent } from "../../contexts/ComponentContext";
import { useMenu } from "../../contexts/MenuContextV2";

const { Text, Title } = Typography;
const { TabPane } = Tabs;

interface MeasureModalProps extends ModalProps {
	closeModal?: () => void;
	measure?: MeasureType;
}

const MeasureModal = ({
	onCancel = () => {},
	closeModal = () => {},
	measure,
	...restProps
}: MeasureModalProps) => {
	const { ismobile } = useSelector((state: any) => state);
	const [form] = Form.useForm();
	const {
		tables,
		state: customDashboardState,
		measureState,
		measureDispatch,
	} = useContext(CustomDashboardContext);
	const { menuItem, isOwner, isAdmin } = useComponent();
	const { parameters } = useCustomDashboard();
	const currentUser = useSelector((state: any) => state.user);
	const menu = useSelector((state: any) => state.allModules.mainMenu);

	// configurations
	const allowModeChange = false;
	const showDataExplorer = measureState.editMode;

	const [formValues, setFormValues] = useState<any>({});
	const [unsaveModal, setUnsaveModal] = useState(false);
	const [dirty, setDirty] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");

	const handleRunQuery = () => {
		const mastered = findMenuItemByKey(
			menu,
			formValues.menuKey || menuItem?.key
		);
		queryMeasure(mastered, menuItem, formValues, currentUser, isOwner, {
			...customDashboardState.sliceValues,
			...formValues?.params,
		}).then((data) => {
			if (data?.response) {
				measureDispatch({
					type: ACTIONTYPES.PREVIEWDATA,
					payload: data,
				});
			} else {
				measureDispatch({
					type: ACTIONTYPES.ERROR,
					payload: data,
				});
			}
		});
	};

	const handleCloseModal = useCallback(
		(ev: any) => {
			if (dirty) {
				setUnsaveModal(true);
			} else {
				onCancel(ev);
				measureDispatch({ type: ACTIONTYPES.RESET });
				localStorage.removeItem(`menu.${menuItem.key}`);
				setUnsaveModal(false);
				form.resetFields();
			}
		},
		[onCancel, menuItem.key, dirty]
	);

	const handleDiscardChanges = () => {
		localStorage.removeItem(`menu.${menuItem.key}`);
		measureDispatch({ type: ACTIONTYPES.RESET });
		setUnsaveModal(false);
		setDirty(false);
		form.resetFields();
	};

	const handleNext = useCallback(() => {
		measureDispatch({ type: ACTIONTYPES.GO_NEXT });
	}, []);

	const renderFooter = () => {
		return (
			<Flex justify="space-between">
				<Space>
					<Form.Item required noStyle valuePropName="checked" name={"verified"}>
						<Switch
							disabled={
								!isAdmin || measureState.loading || !measureState.editMode
							}
						/>
					</Form.Item>
					Verified
					<Tooltip
						overlayInnerStyle={{ fontSize: 16 }}
						placement="right"
						title={
							measure?.verifiedBy
								? `Verified by ${measure?.verifiedBy} on ${dayjs(
										measure?.verifiedAt
								  ).format("DD-MM-YYYY hh:mm:ss a")}`
								: null
						}
					>
						<SafetyCertificateOutlined style={{ fontSize: 16 }} />
					</Tooltip>
				</Space>
				<Space>
					<Button disabled={measureState.loading} onClick={handleCloseModal}>
						Cancel
					</Button>

					{measureState.editMode && (
						<>
							{!errorMessage ? (
								<Button
									type="primary"
									disabled={measureState.loading || !dirty}
									onClick={handleNext}
								>
									Next
								</Button>
							) : (
								<Popconfirm
									title="Error"
									icon={<ExclamationCircleOutlined style={{ color: "red" }} />}
									description={<pre>{errorMessage}</pre>}
									cancelButtonProps={{ style: { display: "none" } }}
									overlayStyle={{ zIndex: 30003 }}
									disabled={measureState.loading || !dirty}
									placement="topLeft"
								>
									<Button
										type="primary"
										disabled={measureState.loading || !dirty}
									>
										Next
									</Button>
								</Popconfirm>
							)}
						</>
					)}
				</Space>
			</Flex>
		);
	};

	const onValuesChange = (changedValue: any) => {
		setFormValues((prev: any) => ({ ...prev, ...changedValue }));
		setDirty(true);
	};

	// EFFECTS
	useEffect(() => {
		if (dirty) {
			const timer = setTimeout(() => {
				localStorage.setItem(
					`menu.${menuItem.key}`,
					JSON.stringify(formValues)
				);
			}, 2000); // Auto-save after 2 seconds of no changes
			// Cleanup timer if there are further changes
			return () => {
				clearTimeout(timer);
			};
		}
	}, [formValues, dirty]);

	useEffect(() => {
		measureDispatch({ type: ACTIONTYPES.LOADING, payload: true });
		duckQuery("SHOW TABLES", [])
			.then((data: any) => {
				const { response: tableNameArr = [] } = data;

				getShares()
					.then((shares: any) => {
						const promises = shares.map(getAllTables);

						Promise.all(promises).then((tablesArr: any) => {
							let tables: Array<any> = [];
							tablesArr.forEach((tableArr: any) => {
								tables = [...tables, ...tableArr];
							});
							tables = tables.filter((tbl: any) =>
								tableNameArr.find((t: any) => t.name === tbl.name)
							);
							measureDispatch({ type: ACTIONTYPES.TABLES, payload: tables });
							measureDispatch({ type: ACTIONTYPES.LOADING, payload: false });
						});
					})
					.catch((error) => {
						console.error(error);
					});
			})
			.catch((error) => {
				console.error(error);
			});
	}, []);

	useEffect(() => {
		if (measure) {
			form.setFieldsValue({
				...measure,
				params: customDashboardState.slicerParams,
			});

			setFormValues({ ...measure, params: customDashboardState.slicerParams });
		}
	}, [measure, customDashboardState.slicerParams]);

	const renderQueryBuilderContent = () => (
		<Space direction="vertical" style={{ padding: 24 }}>
			<Title style={{ fontSize: 16 }}>Query Builder</Title>
			<span>Parameters</span>
			<QueryParameters
				parameters={parameters}
				paramValues={formValues?.params}
				onChange={(params) => {
					setFormValues((prev: any) => ({ ...prev, params }));
				}}
			/>
			<Form.Item
				className="query-input"
				name={"queryStatement"}
				label={"Query"}
				rules={[
					{ required: true },
					{
						validator: (rule, value, callback) => {
							const requiredParams = ["start_date", "end_date"];

							const required: Array<string> = [];
							let stringifiedQuery = JSON.stringify(value);

							for (const field of requiredParams) {
								const param = `@${field}`;
								const indexOfParam = stringifiedQuery?.indexOf(param);
								if (indexOfParam === -1) {
									required.push(param);
								}
							}

							if (required.length === 0) {
								setErrorMessage("");
								callback();
							} else {
								setErrorMessage(getErrorMessage(required));
								callback(
									"Please ensure that mandatory parameters are provided in the query"
								);
							}
						},
					},
				]}
			>
				<CustomEditor
					readOnly={!measureState.editMode}
					onKeyDownCapture={(ev) => {
						if (ev.ctrlKey && ev.code === "Enter") {
							handleRunQuery();
							ev.preventDefault();
						}
					}}
					completions={generateCompletions(measureState.tables, tables)}
				/>
			</Form.Item>
			{measureState.editMode ? (
				<Space size={12} align="start">
					<Button
						onClick={handleRunQuery}
						type="primary"
						size="small"
						icon={<RedoOutlined />}
						loading={measureState.queryloading}
						style={{ width: "max-content" }}
					>
						Run
					</Button>
					<StatusHandler
						dirty={measureState.querydirty}
						loading={measureState.queryloading}
						errorMessage={measureState.errorMsg}
					/>
				</Space>
			) : null}
			{measureState.editMode ? (
				<>
					<Space>
						<Text>Result</Text>
						<Badge
							color="#52c41a"
							count={measureState.previewdata.length}
							showZero={false}
							overflowCount={5000}
							style={{ color: "#fff" }}
						/>
					</Space>
					{measureState.querydirty ? (
						<PreviewTable
							data={measureState.previewdata}
							rootClassName="preview-table"
							style={{ height: "100%" }}
						/>
					) : (
						<Result
							className="clean-result"
							title="Please complete your query to view the results"
						/>
					)}
				</>
			) : null}
		</Space>
	);

	return (
		<>
			<Modal
				{...restProps}
				width={measureState.editMode ? "95%" : "70%"}
				title={
					!measureState.isNew
						? measureState.editMode
							? `Edit Measure - ${measure?.name}`
							: `View Measure - ${measure?.name}`
						: "New Measure"
				}
				onCancel={handleCloseModal}
				destroyOnClose
				maskClosable={false}
				style={{ top: 24 }}
				styles={{
					body: { padding: 0 },
				}}
				className="measure-modal"
				footer={renderFooter()}
				modalRender={(dom) => (
					// Main Form
					<Form
						requiredMark={"optional"}
						form={form}
						layout="vertical"
						className="create-measure-form"
						onValuesChange={onValuesChange}
					>
						{dom}
					</Form>
				)}
			>
				<Spin spinning={measureState.loading} style={{ width: "100%" }}>
					{!ismobile ? (
						<div
							style={{
								display: "grid",
								width: "100%",
								gridTemplateColumns: showDataExplorer
									? "repeat(2, 50%)"
									: "repeat(1, 100%)",
							}}
						>
							{renderQueryBuilderContent()}
							<Space
								className="dataset-explorer"
								direction="vertical"
								style={{
									display: showDataExplorer ? undefined : "none",
									background: "#141414",
									padding: 24,
								}}
								styles={{
									item: {
										width: "100%",
									},
								}}
							>
								<DataExplorer />
							</Space>
						</div>
					) : (
						<Tabs defaultActiveKey="1" centered>
							<TabPane tab="Query Builder" key="1">
								<div style={{ maxWidth: "100%" }}>
									{renderQueryBuilderContent()}
								</div>
							</TabPane>
							<TabPane tab="Data Explorer" key="2">
								<Space
									className="dataset-explorer"
									direction="vertical"
									style={{
										display: showDataExplorer ? undefined : "none",
										padding: 24,
									}}
									styles={{
										item: {
											width: "100%",
										},
									}}
								>
									<DataExplorer />
								</Space>
							</TabPane>
						</Tabs>
					)}
				</Spin>
				<NameDescriptionModal
					form={form}
					open={measureState.page === PAGE_TYPE.NAMEDESC}
					onCancel={() => {
						measureDispatch({ type: ACTIONTYPES.GO_PREV });
					}}
					onSave={() => {
						localStorage.removeItem(`menu.${menuItem.key}`);
					}}
					closeModal={() => {
						closeModal();
						measureDispatch({ type: ACTIONTYPES.GO_PREV });
					}}
					maskClosable={false}
					style={{ zIndex: 30004 }}
					destroyOnClose
					okButtonProps={{ disabled: !dirty }}
				/>
				<Modal
					title="Unsaved changes"
					okText="Discard Changes"
					cancelText="Continue Editing"
					open={unsaveModal}
					onCancel={() => setUnsaveModal(false)}
					onOk={handleDiscardChanges}
				>
					You have unsaved changes. Do you want to discard them or continue
					editing?
				</Modal>
			</Modal>
		</>
	);
};

export default MeasureModal;
