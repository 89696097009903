import { Table, TableProps } from "antd";
import { useContext, useMemo } from "react";
import { useMainContext } from "../contexts/MainContext";
import { useDataExplorer } from "../contexts/DataExplorerContext";
import { CustomDashboardContext } from "../contexts/context";
import { findMenuItemByKey } from "../utils/utils";
import { useComponent } from "../contexts/ComponentContext";
import { useMenu } from "../contexts/MenuContextV2";

interface SchemaTableProps extends TableProps<any> {}

const SchemaTable = ({ ...restProps }: SchemaTableProps) => {
	const { dispatch, state } = useDataExplorer();
	const { metadatas: tables } = useMainContext();
	const { measureState } = useContext(CustomDashboardContext);
	const { menu } = useMenu();
	const { menuItem } = useComponent();

	const onRow = (record: any) => {
		return {
			onClick: () => {
				dispatch({ type: "SCHEMA", payload: record.key });
			},
			style: { cursor: "pointer" },
		};
	};

	const dataSource: Array<any> = useMemo(() => {
		if (tables?.length === 0) return [];
		if (measureState?.measure?.menuKey || menuItem?.key) {
			const masteredMenuItem = findMenuItemByKey(
				menu,
				measureState?.measure?.menuKey || menuItem?.key
			);

			const schemas = tables
				?.map((tbl) => tbl?.schema)
				?.reduce((acc, curr) => {
					if (!acc.includes(curr)) acc.push(curr);
					return acc;
				}, [])
				?.filter((schema: string) =>
					masteredMenuItem?.schema_authorized?.includes(schema)
				)
				?.map((schema: any) => {
					return { schema, key: schema };
				});

			if (state.searchVal && state.searchVal !== "")
				return schemas?.filter((el: any) =>
					el.schema.includes(state.searchVal)
				);

			return schemas;
		} else {
			return [];
		}
	}, [
		tables,
		state.searchVal,
		menu,
		measureState?.measure?.menuKey,
		menuItem?.key,
	]);

	const columns = useMemo(() => {
		if (dataSource.length === 0) return [];
		return [{ dataIndex: "schema", title: "Schema", key: "schema" }];
	}, [dataSource]);

	return (
		<Table
			{...restProps}
			bordered
			rootClassName="schema-table"
			size="small"
			dataSource={dataSource}
			columns={columns}
			onRow={onRow}
		/>
	);
};

export default SchemaTable;
