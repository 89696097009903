import { createContext, useContext } from "react";

type ComponentContextProps = {
	menuItem: Record<string, any>;
	isAdmin?: boolean;
	isOwner?: boolean;
	isViewer?: boolean;
};

type ComponentProviderProps = {
	menuItem: Record<string, any>;
	children?: React.ReactNode;
	isAdmin?: boolean;
	isOwner?: boolean;
	isViewer?: boolean;
};

// A context that wraps all Portal components providing the necessary data like (Measures, Visuals and Parameters)
// Holds the information of the menu item
const ComponentContext = createContext<ComponentContextProps | undefined>(
	undefined
);

const ComponentProvider = ({
	children,
	menuItem,
	isAdmin,
	isOwner,
	isViewer,
}: ComponentProviderProps) => {
	return (
		<ComponentContext.Provider
			value={{
				menuItem,
				isAdmin,
				isOwner,
				isViewer,
			}}
		>
			{children}
		</ComponentContext.Provider>
	);
};

const useComponent = () => {
	const context = useContext(ComponentContext);

	if (!context) {
		throw new Error("useComponent must be used within a ComponentProvider");
	}

	return context;
};

export { ComponentProvider, useComponent };
