import {
	CaretDownFilled,
	CaretUpFilled,
	CloseCircleFilled,
	SafetyCertificateOutlined,
} from "@ant-design/icons";
import { Space, SpaceProps, Tag, Tooltip, Typography } from "antd";
import { PresetColorType } from "antd/es/_util/colors";
import { TargetType } from "./TargetsConfig";
import dayjs from "dayjs";
import { useMemo } from "react";
import duration from "dayjs/plugin/duration";
import { VisualType } from "../types/Visual";

dayjs.extend(duration);

const { Text } = Typography;
const DECIMAL_PLACES = 2;

type CustomStatisticProps = SpaceProps & {
	valueObj?: any;
	indicator_value?: number;
	targets?: TargetType;
	visual?: VisualType;
	measure?: MeasureType;
	target: number;
};

const CustomStatistic = ({
	valueObj: value,
	indicator_value,
	targets,
	visual,
	measure,
	target,
	...restProps
}: CustomStatisticProps) => {
	const getVerifiedContent = useMemo(() => {
		if (!measure?.verified) return null;

		return `Verified by ${measure?.verifiedBy} on ${dayjs(
			measure?.verifiedAt
		).format("DD-MM-YYYY hh:mm:ss a")}`;
	}, [measure]);

	// Explicitly check for null or undefined, allowing 0 to be displayed
	if (value === null || value === undefined)
		return <Text>No results found</Text>;

	if (value === undefined) {
		return <Text type="danger">"{visual?.data?.value}" is not found</Text>;
	}

	if (typeof value !== "number" && !value) {
		return <Text>No results found</Text>;
	}

	return (
		<Space
			{...restProps}
			style={{ width: "100%", height: "100%", padding: 24 }}
			direction="vertical"
		>
			<Space>
				<Text style={{ fontSize: "clamp(1rem, 2rem, 32px)" }}>
					{typeof value === "number"
						? Number(value)
						: // .toFixed(DECIMAL_PLACES)
						  value}
				</Text>
				{measure?.verified ? (
					<Tooltip
						overlayInnerStyle={{ fontSize: 16 }}
						title={getVerifiedContent}
						placement="right"
					>
						<SafetyCertificateOutlined style={{ fontSize: 14 }} />
					</Tooltip>
				) : null}
			</Space>
			{targets && (
				<TargetHandler
					visual={visual}
					target={target}
					value={indicator_value ?? value}
					indicator={visual?.indicator}
					tolerancePercent={targets?.tolerance_percent || 0}
				/>
			)}
		</Space>
	);
};

type TargetHandlerProps = {
	value: number;
	target: number;
	indicator?: string;
	tolerancePercent: number;
	visual?: VisualType;
};

const TargetHandler = ({
	value,
	target,
	indicator,
	tolerancePercent,
	visual,
}: TargetHandlerProps) => {
	const difference = calPercentageDifference(Number(value), Number(target));
	const color = getDifferencecolor(
		Number(Number(value).toFixed(DECIMAL_PLACES)),
		target,
		tolerancePercent,
		indicator
	);

	return (
		<>
			<CustomDiffereceRenderer difference={difference} color={color} />
			<Text>
				vs target{" "}
				{visual?.target_unit_type === "custom" ? (
					visual.target_unit === "dollar" ? (
						<>$</>
					) : visual.target_unit === "pound" ? (
						<>&pound;</>
					) : null
				) : null}
				{!isNaN(target)
					? visual?.target_unit === "time" &&
					  visual?.target_unit_type === "custom"
						? dayjs
								.duration(
									target,
									// visual?.timestamp_unit as DurationUnitType
									"seconds"
								)
								.format("HH:mm:ss")
						: Number(target)
					: // .toFixed(DECIMAL_PLACES)
					  target}
				{visual?.target_unit_type === "custom" ? (
					visual?.target_unit === "percentage" ? (
						<>%</>
					) : null
				) : null}
			</Text>
		</>
	);
};

const CustomDiffereceRenderer = ({
	difference = 0,
	color,
}: {
	difference?: number | string;
	color?: PresetColorType;
}) => {
	return (
		<Space size={4} style={{ color }}>
			<Tag color={color}>
				<Space>
					{color === "red" ? (
						<CloseCircleFilled />
					) : color === "green" ? (
						<CaretUpFilled />
					) : (
						<CaretDownFilled />
					)}
					{difference}
				</Space>
			</Tag>
		</Space>
	);
};

const calPercentageDifference = (value: number, target: number) => {
	if (target === 0) {
		if (value === 0) {
			return `${Number(0).toFixed(1)}%`; // Both value and target are 0, no difference
		} else {
			return "> 100%"; // Target is 0, any non-zero value leads to infinite difference
		}
	}
	let difference = ((value - target) / target) * 100;
	return `${Number(difference).toFixed(1)}%`;
};

const getDifferencecolor = (
	value: number,
	target: number,
	tolerance_percent: number,
	indicator?: string
): PresetColorType => {
	if (target === value) {
		return "green";
	}

	if (target === 0 && value === 0) {
		if (indicator === "above") {
			return "green";
		} else {
			return "red";
		}
	}

	// Handle the edge case where both min and max are zero (target 0)
	if (target === 0) {
		if (indicator === "below") {
			return value < target ? "green" : "red";
		} else {
			return value > target ? "green" : "red";
		}
	}

	if (indicator === "below") {
		const [newMin, newMax] = [
			target + (target * tolerance_percent) / 100,
			target,
		].sort((a, b) => a - b); // sort min max
		if (value >= newMax) {
			return "red";
		} else if (value < newMax && value >= newMin) {
			return "orange";
		} else {
			return "green";
		}
	} else {
		const [newMin, newMax] = [
			target,
			target - (target * tolerance_percent) / 100,
		].sort((a, b) => a - b); // sort min max
		if (value >= newMax) {
			return "green";
		} else if (value < newMax && value >= newMin) {
			return "orange";
		} else {
			return "red";
		}
	}
};

export const getTarget = (
	target: any,
	target_multiplier: string,
	target_based_on: string,
	sliceValues: any
) => {
	if (target && target_multiplier === "multiply") {
		if (sliceValues?.[target_based_on])
			return target * sliceValues?.[target_based_on]?.length;
	}

	return target;
};

export default CustomStatistic;
