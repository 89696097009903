import React, { useContext, useEffect, useRef, useState } from "react";
import type { FormInstance, InputRef } from "antd";
import { Button, Form, Input } from "antd";

const EditableContext = React.createContext<FormInstance<any> | null>(null);

interface Item {
	key: string;
	value: string;
	displayName: string;
	include: boolean;
}

interface EditableRowProps {
	index: number;
}

export const EditableRow: React.FC<EditableRowProps> = ({
	index,
	...props
}) => {
	const [form] = Form.useForm();
	return (
		<Form form={form} component={false}>
			<EditableContext.Provider value={form}>
				<tr {...props} />
			</EditableContext.Provider>
		</Form>
	);
};

interface EditableCellProps {
	title: React.ReactNode;
	editable: boolean;
	dataIndex: keyof Item;
	record: Item;
	transformValue?: (value: string) => void;
	handleSave: (record: Item) => void;
}

export const EditableCell: React.FC<
	React.PropsWithChildren<EditableCellProps>
> = ({
	title,
	editable,
	children,
	dataIndex,
	record,
	handleSave,
	transformValue,
	...restProps
}) => {
	const [editing, setEditing] = useState(false);
	const inputRef = useRef<InputRef>(null);
	const form = useContext(EditableContext)!;

	useEffect(() => {
		if (editing) {
			inputRef.current?.focus();
		}
	}, [editing]);

	const toggleEdit = () => {
		setEditing(!editing);
		form.setFieldsValue({ [dataIndex]: record[dataIndex] });
	};

	const save = async () => {
		try {
			const values = await form.validateFields();

			if (transformValue && dataIndex) {
				values[dataIndex] = transformValue(values[dataIndex]);
			}

			toggleEdit();

			if (values[dataIndex]) handleSave({ ...record, ...values });
		} catch (errInfo) {
			console.log("Save failed:", errInfo);
		}
	};

	const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const newValue = transformValue
			? transformValue(e.target.value)
			: e.target.value;

		form.setFieldsValue({ [dataIndex]: newValue });
	};

	let childNode = children;

	if (editable) {
		childNode = editing ? (
			<Form.Item
				style={{ margin: 0 }}
				name={dataIndex}
				// rules={[{ required: true, message: `${title} is required` }]}
			>
				<Input
					size="small"
					ref={inputRef}
					onPressEnter={save}
					onBlur={save}
					onChange={handleInputChange}
				/>
			</Form.Item>
		) : (
			<div
				className="editable-cell-value-wrap"
				style={{ paddingInlineEnd: 24 }}
				onClick={toggleEdit}
			>
				{children}
			</div>
		);
	}

	return <td {...restProps}>{childNode}</td>;
};
