import { DashboardOutlined, MoreOutlined } from "@ant-design/icons";
import { Dropdown, MenuProps } from "antd";
import { VisualType } from "../types/Visual";
import { useCallback, useContext, useMemo } from "react";
import { CustomDashboardContext } from "../contexts/context";
import { useCustomDashboard } from "../contexts/CustomDashboardContext";
import { findMeasureById } from "../utils/utils";
import { useComponent } from "../contexts/ComponentContext";

type VisualActionsDropdownType = {
	visual: VisualType;
};

const VisualActionsDropdown = ({ visual }: VisualActionsDropdownType) => {
	const {
		measureDispatch,
		visualDispatch,
		state: customDashboardState,
	} = useContext(CustomDashboardContext);
	const { menuItem: masteredMenuItem, isOwner } = useComponent();
	const { measures } = useCustomDashboard();

	const handleShowMeasure = useCallback(() => {
		// Find where the measure was mastered from
		const measure = findMeasureById(measures, visual.measure);

		if (measure?.menuKey === masteredMenuItem.key && isOwner) {
			measureDispatch({ type: "EDIT_MEASURE", payload: measure });
		} else {
			measureDispatch({ type: "VIEW_MEASURE", payload: measure });
		}
	}, [visual.measure, masteredMenuItem.key, measures]);

	const handleShowVisual = useCallback(() => {
		// Find where the visual was mastered from
		if (isOwner) {
			visualDispatch({ type: "EDIT_VISUAL", payload: visual });
		} else {
			visualDispatch({ type: "VIEW_VISUAL", payload: visual });
		}
	}, [visual, isOwner]);

	const items: MenuProps["items"] = useMemo(() => {
		const defaultItems = [
			{
				key: "measure",
				label: "View measure",
				icon: <DashboardOutlined style={{ fontSize: 16 }} />,
				onClick: handleShowMeasure,
			},
		];
		return isOwner
			? [
					{
						key: "measure",
						label: "View measure",
						icon: <DashboardOutlined style={{ fontSize: 16 }} />,
						onClick: handleShowMeasure,
					},
					{
						key: "visual",
						label: "View visual",
						icon: <DashboardOutlined style={{ fontSize: 16 }} />,
						onClick: handleShowVisual,
					},
			  ]
			: defaultItems;
	}, [isOwner, handleShowVisual, handleShowMeasure]);
	return (
		<Dropdown
			disabled={customDashboardState.editMode}
			menu={{ items }}
			trigger={["click"]}
		>
			<MoreOutlined />
		</Dropdown>
	);
};

export default VisualActionsDropdown;
