import { HourglassOutlined, LockOutlined } from "@ant-design/icons";
import { Button, Flex, Typography, Layout, Card, Spin } from "antd";
import { CSSProperties, useState } from "react";
import RequestAccessModal from "./modal/RequestAccessModal";
import { useAccessRequestContext } from "../contexts/AccessRequestContext";
import { useSelector } from "react-redux";
import { useComponent } from "../contexts/ComponentContext";

type RequestAccessScreenProps = {};

const { Text, Paragraph } = Typography;

const paragraphStyle: CSSProperties = {
	color: "rgba(255, 255, 255, 0.65)",
};

const RequestAccessScreen = ({}: RequestAccessScreenProps) => {
	const user = useSelector((state: any) => state.user);
	const { menuItem } = useComponent();

	const [showModal, setShowModal] = useState(false);
	const { accessRequests, loading } = useAccessRequestContext();

	const userRequests = accessRequests?.filter(
		(_request) => _request.requesterId === user?.oid
	);
	const allowRequest =
		userRequests?.filter((_request) => _request.status === "Requested")
			.length === 0;

	const openRequestModal = () => {
		setShowModal(true);
	};

	const closeRequestModal = () => {
		setShowModal(false);
	};

	return (
		<>
			<Layout style={{ background: "rgba(20, 20, 20, 0.45)", height: "100%" }}>
				<Flex justify="center" align="center" style={{ height: "100%" }}>
					<Spin spinning={loading}>
						<Card
							style={{
								width: 600,
								borderRadius: 0,
								border: "1px solid #112A45",
								background: "transparent",
							}}
							styles={{
								body: {
									padding: "48px 64px",
									background: "rgba(20, 20, 20, 0.25)",
								},
							}}
						>
							{allowRequest ? (
								<Flex vertical align="center" gap={24}>
									<LockOutlined style={{ fontSize: 104, opacity: 0.75 }} />
									<Text
										style={{
											fontSize: 30,
											color: "rgba(255, 255, 255, 0.85)",
											textAlign: "center",
										}}
									>
										Sorry, you don't have access to {menuItem?.title}
									</Text>
									<div style={{ alignSelf: "start" }}>
										{menuItem?.description ? (
											<Paragraph style={paragraphStyle}>
												{menuItem?.description}
											</Paragraph>
										) : null}

										<Paragraph
											style={{ ...paragraphStyle, fontWeight: "bold" }}
										>
											To view this component, please request access from the
											component owner using the button below.
										</Paragraph>
									</div>
									<Button disabled={!allowRequest} onClick={openRequestModal}>
										Request access
									</Button>
								</Flex>
							) : (
								<Flex vertical align="center" gap={24}>
									<HourglassOutlined style={{ fontSize: 104, opacity: 0.75 }} />
									<Text
										style={{
											fontSize: 30,
											color: "rgba(255, 255, 255, 0.85)",
											textAlign: "center",
										}}
									>
										Pending approval to {menuItem?.title}
									</Text>
									<div style={{ alignSelf: "start" }}>
										<Paragraph style={paragraphStyle}>
											Your request for access is currently pending approval.
											Please note that approval may take some time.
										</Paragraph>
									</div>
								</Flex>
							)}
						</Card>
					</Spin>
				</Flex>
			</Layout>
			<RequestAccessModal
				open={showModal}
				menuItem={menuItem}
				onCancel={closeRequestModal}
			/>
		</>
	);
};

export default RequestAccessScreen;
