import { useEffect, useMemo, useRef, useState } from "react";
import {
	Button,
	Input,
	InputRef,
	Modal,
	ModalProps,
	Space,
	Table,
	TableColumnsType,
	TableColumnType,
	Tooltip,
	Typography,
} from "antd";
import { FilterDropdownProps } from "antd/es/table/interface";
import { SafetyCertificateOutlined, SearchOutlined } from "@ant-design/icons";
import CustomParagraph from "../CustomParagraph";
import dayjs from "dayjs";
import { useCustomDashboard } from "../../contexts/CustomDashboardContext";
import {
	findMeasureById,
	findMenuItemByKey,
	queryMeasure,
} from "../../utils/utils";
import { useSelector } from "react-redux";
import { useComponent } from "../../contexts/ComponentContext";
import { useMenu } from "../../contexts/MenuContextV2";

const { Link } = Typography;

type DrilldownModalProps = ModalProps & {
	drilldownId?: string;
	sliceValues?: Record<string, any>;
	visualParams?: Record<string, any>;
	onDrilldown?: (measure: MeasureType) => void;
};

const DrilldownModal = ({
	drilldownId,
	sliceValues = {},
	visualParams = {},
	onDrilldown = () => {},
	...restProps
}: DrilldownModalProps) => {
	const searchInput = useRef<InputRef>(null);

	// commented for now, would be usefull for highlighting searched text in table in the future
	// const [searchText, setSearchText] = useState("");
	// const [searchedColumn, setSearchedColumn] = useState("");

	const [currentPageSize, setCurrentPageSize] = useState(50);
	const { menuItem, isOwner } = useComponent();
	const { measures } = useCustomDashboard();
	const [drilldownData, setDrilldownData] = useState<
		Array<Record<string, any>>
	>([]);
	const [drilldownDataLoading, setDrilldownDataLoading] = useState(false);

	const { menu } = useMenu();
	const currentUser = useSelector((state: any) => state.user);

	const handleSearch = (
		selectedKeys: string[],
		confirm: FilterDropdownProps["confirm"],
		dataIndex: string
	) => {
		confirm();
		// setSearchText(selectedKeys[0]);
		// setSearchedColumn(dataIndex);
	};

	const handleReset = (
		clearFilters: () => void,
		confirm: FilterDropdownProps["confirm"]
	) => {
		clearFilters();
		confirm();
		// setSearchText("");
	};

	const getColumnSearchProps = (dataIndex: string): TableColumnType<any> => ({
		filterDropdown: ({
			setSelectedKeys,
			selectedKeys,
			confirm,
			clearFilters,
			close,
		}: any) => (
			<div
				className="filter-dropdown"
				style={{
					padding: 8,
					background: "#1f1f1f",
					border: "1px solid #303030",
					borderRadius: 8,
				}}
				onKeyDown={(e) => e.stopPropagation()}
			>
				<Input
					ref={searchInput}
					placeholder={`Search ${dataIndex}`}
					value={selectedKeys[0]}
					onChange={(e) =>
						setSelectedKeys(e.target.value ? [e.target.value] : [])
					}
					onPressEnter={() =>
						handleSearch(selectedKeys as string[], confirm, dataIndex)
					}
					style={{ marginBottom: 8, display: "block" }}
				/>
				<Space>
					<Button
						type="primary"
						onClick={() =>
							handleSearch(selectedKeys as string[], confirm, dataIndex)
						}
						icon={<SearchOutlined />}
						size="small"
						style={{ width: 90 }}
					>
						Search
					</Button>
					<Button
						onClick={() => clearFilters && handleReset(clearFilters, confirm)}
						size="small"
						style={{ width: 90 }}
					>
						Reset
					</Button>
					<Button
						type="link"
						size="small"
						onClick={() => {
							close();
						}}
					>
						close
					</Button>
				</Space>
			</div>
		),
		filterIcon: (filtered: boolean) => (
			<SearchOutlined style={{ color: filtered ? "#1677ff" : undefined }} />
		),
		onFilter: (value, record) => {
			const fieldValue = record[dataIndex] ?? "null";
			return fieldValue
				.toString()
				.toLowerCase()
				.includes((value as string).toLowerCase());
		},
		onFilterDropdownOpenChange: (visible) => {
			if (visible) {
				setTimeout(() => searchInput.current?.select(), 100);
			}
		},
	});

	const columns: TableColumnsType<any> | undefined = useMemo(() => {
		if (drilldownData?.length && drilldownData?.length > 0) {
			return Object.keys(drilldownData[0]).map((k: any, i) => {
				return {
					dataIndex: k,
					key: k,
					title: k,
					width: 200,
					render: (value, record, index) => {
						return <CustomParagraph>{value}</CustomParagraph>;
					},
					// render(value, record, index) {
					// 	if (k == "Description") {
					// 		return "Long text";
					// 	}

					// 	return (
					// 		<Paragraph ellipsis={{ expandable: true, rows: 3 }}>
					// 			{value}
					// 		</Paragraph>
					// 	);
					// },
					...getColumnSearchProps(k),
				};
			});
		} else {
			return undefined;
		}
	}, [drilldownData]);

	const measure = useMemo(
		() => findMeasureById(measures, drilldownId || ""),
		[measures, drilldownId]
	);

	useEffect(() => {
		const fetchDrilldownData = async () => {
			setDrilldownDataLoading(true);
			try {
				const mastered = findMenuItemByKey(menu, measure?.menuKey || "");
				if (measure) {
					const data = await queryMeasure(
						mastered,
						menuItem,
						measure,
						currentUser,
						isOwner,
						sliceValues,
						visualParams
					);
					setDrilldownData(data.response);
				}
			} catch (error) {
				console.log(error);
			} finally {
				setDrilldownDataLoading(false);
			}
		};

		if (measure) {
			fetchDrilldownData();
		}
		return () => {
			setCurrentPageSize(50);
		};
	}, [
		drilldownId,
		measures,
		menu,
		menuItem,
		currentUser,
		isOwner,
		JSON.stringify(sliceValues),
		JSON.stringify(visualParams),
		measure,
	]);

	if (!measure) return null;

	return (
		<Modal
			{...restProps}
			destroyOnClose
			width={"80%"}
			okButtonProps={{ style: { display: "none" } }}
			cancelButtonProps={{ type: "primary" }}
			title={
				<Space>
					<Link
						style={{ color: "#fff", fontSize: 16 }}
						onClick={() => {
							onDrilldown(measure);
						}}
					>
						{measure?.name}
					</Link>
					{measure?.verified ? (
						<Tooltip
							overlayInnerStyle={{ fontSize: 16, width: "max-content" }}
							placement="right"
							title={
								measure?.verifiedBy
									? `Verified by ${measure?.verifiedBy} on ${dayjs(
											measure?.verifiedAt
									  ).format("DD-MM-YYYY hh:mm:ss a")}`
									: null
							}
						>
							<SafetyCertificateOutlined style={{ fontSize: 16 }} />
						</Tooltip>
					) : null}
				</Space>
			}
			maskClosable={false}
			cancelText="Close"
		>
			<Table
				size="small"
				bordered
				loading={drilldownDataLoading}
				dataSource={drilldownData}
				columns={columns}
				pagination={{
					pageSize: currentPageSize,
					onChange: (page, pageSize) => {
						setCurrentPageSize(pageSize);
					},
				}}
				scroll={{ x: 2000, y: 700 }}
				tableLayout="fixed"
				// virtual // Supports large datasource to be rendered virtually avoiding breaking Portal
			/>
		</Modal>
	);
};

export default DrilldownModal;
