import {
	Button,
	Col,
	Divider,
	Flex,
	Form,
	Input,
	InputNumber,
	InputRef,
	Modal,
	ModalProps,
	Popconfirm,
	Radio,
	Row,
	Select,
	SelectProps,
	Space,
	Table,
	TableProps,
	Tabs,
	Tag,
	Typography,
} from "antd";
import React, {
	useCallback,
	useContext,
	useEffect,
	useState,
	useMemo,
	useRef,
} from "react";
import { addVisual, updateVisual } from "../../services/api-server/visuals";
import Emitter from "../../services/EventEmitter";
import { CustomDashboardContext } from "../../contexts/context";
import { TargetProps } from "../TargetsConfig";
import PlotInputs from "../PlotInputs";
import Visual from "../Visual";
import PreviewTable from "../PreviewTable";
import TargetsConfigTab, { MultiplierButton } from "../TargetsConfigTab";
import CustomEditor from "../CustomEditor";
import useMenu from "../../hooks/useMenu";
import { getParameters } from "../../services/api-server/parameters";
import { useSelector } from "react-redux";
import { useCustomDashboard } from "../../contexts/CustomDashboardContext";
import { useMenu as useMenuV2 } from "../../contexts/MenuContextV2";
import {
	findMeasureById,
	findMenuItemByKey,
	queryMeasure,
} from "../../utils/utils";
import { useComponent } from "../../contexts/ComponentContext";
import {
	createTag,
	deleteTag,
	updateTag,
} from "../../services/api-server/visual_tags";
import { EditableCell, EditableRow } from "../EditableCell";

interface VisualModalProps extends ModalProps {}

type ColumnTypes = Exclude<TableProps<VisualTag>["columns"], undefined>;

const { Text } = Typography;

const VisualModal = ({ ...restProps }: VisualModalProps) => {
	const inputRef = useRef<InputRef>(null);
	const [tagLabel, setTagLabel] = useState("");
	const [form] = Form.useForm();
	const [formValues, setFormValues] = useState<any>({});
	const [params, setParams] = useState<Record<string, any>>({});
	const [tagsModal, setTagsModal] = useState(false);
	const currentUser = useSelector((state: any) => state.user);
	const { menu } = useMenuV2();

	const { customDashboardModules } = useMenu();

	// FROM CONTENT TAB
	const { menuItem, isOwner } = useComponent();
	const { measures, parameters, visualTags } = useCustomDashboard();
	const {
		state: customDashboardState,
		visualDispatch,
		visualState,
	} = useContext(CustomDashboardContext);

	// Keeping track of the measure to handle query db when measure changed
	const trackMeasureId = Form.useWatch("measure", form);

	const measure = useMemo(
		() => findMeasureById(measures, trackMeasureId),
		[measures, trackMeasureId]
	);

	const columns: ColumnTypes[number] &
		{
			editable?: boolean;
			dataIndex: string;
		}[] = useMemo(() => {
		const defaultColumns: (ColumnTypes[number] & {
			editable?: boolean;
			dataIndex: string;
		})[] = [
			{
				dataIndex: "label",
				key: "label",
				title: "Label",
				editable: true,
			},
			{
				dataIndex: "delete",
				width: 100,
				render: (_, record) => {
					return (
						<Popconfirm
							onConfirm={handleDeleteTag(record)}
							title={`Are you sure you want to delete this tag ${record.label}?`}
						>
							<Button>Delete</Button>
						</Popconfirm>
					);
				},
				align: "center",
			},
		];

		return defaultColumns?.map((col) => {
			if (!col.editable) {
				return col;
			}
			return {
				...col,
				onCell: (record: VisualTag) => ({
					record,
					editable: col.editable,
					dataIndex: col.dataIndex,
					title: col.title,
					transformValue: (value: string) =>
						value.replace(/\s+/g, "_").toLowerCase(),
					handleSave: (row: VisualTag) => {
						// handleFieldValuesChange(
						// 	formValues?.fieldValues?.map((_record: any) => {
						// 		if (_record?.key === row?.key) {
						// 			return { ..._record, ...row };
						// 		}
						// 		return _record;
						// 	})
						// );
						updateTag(row);
					},
				}),
			};
		});
	}, []);

	// ! To prepare used parameters options
	useEffect(() => {
		const handleParams = async (measure: MeasureType) => {
			// * 1. Get parameters from custom dashboard where the measure was mastered from
			if (measure?.menuKey) {
				let masteredParams = await getParameters(measure?.menuKey);

				// * 2. Get parameters that was used in the measure
				let paramsUsed: string[] = [];
				if (measure.queryStatement) {
					const pattern = /@\w+/g;
					// Find all matches in the given text
					const matches = measure.queryStatement.match(pattern) || [];
					paramsUsed = matches.map((match: string) => match.slice(1));
				}

				let filteredParams = masteredParams.filter((param: any) =>
					paramsUsed.includes(param.name)
				);

				if (filteredParams) {
					const params: Record<string, any> = {};

					filteredParams.forEach((param) => {
						let values = param.fieldValues
							.filter((field) => field.include)
							.map((field) => field.value);

						params[param.name] = values;
					});
					setParams(params);
				}
			}
		};

		const loadData = async () => {
			try {
				const mastered = findMenuItemByKey(menu, measure?.menuKey || "");
				const data = await queryMeasure(
					mastered,
					menuItem,
					measure!,
					currentUser,
					isOwner,
					customDashboardState.initialSliceValues,
					formValues?.parameters
				);
				if (data?.response)
					visualDispatch({ type: "DATA", payload: data?.response });
			} catch (error) {
				console.log(error);
			}
		};

		//Check for params of the mastered menu key
		//Check for params used in the measure query
		if (measure) {
			handleParams(measure);
			loadData();
		}
	}, [
		measure,
		menu,
		menuItem,
		currentUser,
		isOwner,
		JSON.stringify(formValues?.parameters),
	]);

	const handleClose = useCallback(() => {
		visualDispatch({ type: "RESET_VISUAL_MODAL" });
		form.resetFields();
	}, []);

	const handleSaveVisual = () => {
		const values = form.getFieldsValue(true);
		const cleanedTargets = cleanTargets(formValues?.targets || {});
		const visual = {
			...values,
			targets: cleanedTargets,
		};

		if (visualState.new) {
			// Handling saving new visual
			addVisual(visual, menuItem.key)
				.then((response) => {
					Emitter.emit("alert", {
						type: "success",
						message: "Visual created successfully",
						description: "You have successfully created a visual",
						timeout: 5000,
					});
					console.log(response);
				})
				.catch((error) => {
					Emitter.emit("alert", {
						type: "error",
						message: "Unsuccessful saving visual",
						description: "There was an error while saving visual",
						timeout: 5000,
					});
					console.error(error);
				})
				.finally(() => {
					visualDispatch({ type: "RESET_VISUAL_MODAL" });
					form.resetFields();
				});
		} else {
			// Handling saving existing visual
			updateVisual(visual, menuItem.key)
				.then((response) => {
					Emitter.emit("alert", {
						type: "success",
						message: "Your visual had been updated",
						description: "You have successfully updated a visual",
						timeout: 5000,
					});
				})
				.catch((error) => {
					Emitter.emit("alert", {
						type: "error",
						message: "Unsuccessful saving visual",
						description: "There was an error while saving visual",
						timeout: 5000,
					});
					console.error(error);
				})
				.finally(() => {
					visualDispatch({ type: "RESET_VISUAL_MODAL" });
					form.resetFields();
				});
		}
	};

	const isSavable = useCallback(() => {
		const { title } = formValues;

		let savable = false;
		if (title && title !== "") savable = true;

		return savable;
	}, [formValues]);

	// MEMOS
	const getMeasureOptions = useMemo(() => {
		return measures.map((measure) => {
			return {
				label: measure.name,
				value: measure.id,
				menuKey: measure.menuKey,
			};
		}) as SelectProps["options"];
	}, [measures]);

	const visualTagsRecord = useMemo(() => {
		return visualTags?.reduce<Record<string, VisualTag>>((acc, curr) => {
			acc[curr._id] = curr;

			return acc;
		}, {});
	}, [visualTags]);

	const addTag = async (
		e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>
	) => {
		e.preventDefault();

		if (tagLabel.trim()) {
			try {
				await createTag({
					label: tagLabel,
					menuKey: menuItem.key,
				});
				setTimeout(() => {
					inputRef.current?.focus();
				}, 0);
				setTagLabel("");
			} catch (error) {
				console.log(error);
			}
		}
	};

	const handleDeleteTag = (record: VisualTag) => {
		return async () => {
			await deleteTag(record);
		};
	};

	useEffect(() => {
		setFormValues({ ...visualState.visual, date_type: "dashboard" });
		form.setFieldsValue({ ...visualState.visual, date_type: "dashboard" });
	}, [visualState.visual]);

	return (
		<Modal
			{...restProps}
			onCancel={handleClose}
			okText={"Save"}
			okButtonProps={{ disabled: !isSavable(), htmlType: "submit" }}
			width={"70%"}
			title={
				visualState.editMode
					? visualState.new
						? "New Visual"
						: `Edit Visual - ${visualState.visual.title}`
					: `View Visual - ${visualState.visual.title}`
			}
			maskClosable={false}
			closeIcon={false}
			destroyOnClose
			modalRender={(dom) => (
				// Main Form
				<Form
					form={form}
					layout="vertical"
					onFinish={() => {
						handleSaveVisual();
					}}
					onValuesChange={(changedValues, values) => {
						setFormValues((prevValues: any) => ({
							...prevValues,
							...values,
						}));
					}}
				>
					{dom}
				</Form>
			)}
		>
			{/* <Spin spinning={visualState.loading}> */}
			<Row gutter={24}>
				<Col flex={1}>
					<PlotInputs formValues={formValues} />

					<Form.Item name={"id"} hidden />
					<Form.Item label="Title" name={"title"}>
						<Input placeholder="Title" />
					</Form.Item>

					<Form.Item
						label={
							<Space>
								Subtitle
								<Text className="custom-optional-text">(optional)</Text>
							</Space>
						}
						name={"subtitle"}
					>
						<Input placeholder="Subtitle" />
					</Form.Item>
				</Col>
				<Divider type="vertical" style={{ height: "auto" }} />
				<Col span={12}>
					<Form.Item>
						{measure ? (
							<Visual
								styles={{ body: { height: 300, width: "auto" } }}
								plotStyle={{ height: 300 }}
								visual={formValues}
								measure={measure}
								staticMode
								sliceValues={customDashboardState.initialSliceValues}
							/>
						) : null}
					</Form.Item>
				</Col>
			</Row>
			<Tabs
				items={[
					{
						key: "measure",
						label: "Measure",
						children: (
							<Row gutter={24}>
								<Col flex={1}>
									<Form.Item layout="vertical" label="Measure" name={"measure"}>
										<Select
											optionFilterProp="label"
											showSearch
											placeholder="Measure"
											options={getMeasureOptions}
											optionRender={(oriOption) => {
												let menu: any = customDashboardModules.find(
													(module: any) =>
														module.key === oriOption.data?.menuKey
												);

												return (
													<Space>
														{oriOption.label}
														{menu ? (
															<span style={{ opacity: "0.6" }}>
																({menu.title})
															</span>
														) : null}
													</Space>
												);
											}}
										/>
									</Form.Item>
									<Form.Item
										layout="vertical"
										label="Value"
										name={["data", "value"]}
									>
										<Select
											dropdownStyle={{ zIndex: 30003 }}
											options={visualState.fields.map((field: any) => ({
												key: field,
												label: field,
												value: field,
											}))}
											placeholder={"Value"}
										/>
									</Form.Item>
									<Form.Item
										layout="vertical"
										label="Measure Indicator Field"
										name={["data", "indicator_value"]}
									>
										<Select
											dropdownStyle={{ zIndex: 30003 }}
											options={visualState.fields.map((field: any) => ({
												key: field,
												label: field,
												value: field,
											}))}
											placeholder={"Indicator field"}
										/>
									</Form.Item>
								</Col>
								<Col flex={1}>
									{visualState?.data?.length ? (
										<Form.Item layout="vertical" label={"Data preview"}>
											<PreviewTable data={visualState?.data} />
										</Form.Item>
									) : null}
								</Col>
								{measure && (
									<CustomEditor
										readOnly={true}
										value={measure?.queryStatement || ""}
									/>
								)}
							</Row>
						),
					},
					{
						key: "targets",
						label: "Targets",
						children: (
							<TargetsConfigTab
								formValues={formValues}
								setFormValues={setFormValues}
							/>
						),
					},
					{
						disabled: !measure,
						key: "parameters",
						label: "Parameters",
						children: (
							<>
								<Text style={{ marginBottom: 16, display: "block" }}>
									Parameters
								</Text>
								<Form.Item label="Dates" name="date_type">
									<Radio.Group style={{ width: "100%" }}>
										<MultiplierButton value={"custom"} disabled>
											Select date range
										</MultiplierButton>
										<MultiplierButton value={"dashboard"}>
											Use dashboard dates
										</MultiplierButton>
									</Radio.Group>
								</Form.Item>
								{formValues?.date_type === "custom" ? (
									<Form.Item label="Date range">
										<Flex gap={12}>
											<Form.Item name={"date_mode"} noStyle>
												<Select
													style={{ width: 100 }}
													options={[
														{ label: "Current", value: "current" },
														{ label: "Previous", value: "previous" },
													]}
												/>
											</Form.Item>
											<Form.Item name={"date_occurrences"} noStyle>
												<InputNumber style={{ width: 48 }} min={1} />
											</Form.Item>
											<Form.Item name={"date_unit"} noStyle>
												<Select
													style={{ width: 100 }}
													options={[
														{ label: "Year", value: "year" },
														{ label: "Quarter", value: "quarter" },
														{ label: "Month", value: "month" },
														{ label: "Week", value: "week" },
														{ label: "Day", value: "day" },
													]}
												/>
											</Form.Item>
										</Flex>
									</Form.Item>
								) : null}
								{Object.entries(params)
									.map(([k, v]: any) => ({
										[k]: v,
										displayName:
											parameters?.find((p) => p.name === k)?.displayName || k,
										options: v?.map((o: any) => ({
											label: o,
											value: o,
										})),
										key: k,
									}))
									?.map((d) => (
										<Form.Item
											layout="vertical"
											key={d?.key}
											label={d?.displayName}
											name={["parameters", d?.key]}
										>
											<Select
												mode="multiple"
												options={d?.options}
												placeholder={d?.displayName}
											/>
										</Form.Item>
									))}
							</>
						),
					},
					{
						key: "drilldown",
						label: "Drilldown",
						children: (
							<Row>
								<Col flex={1}>
									<Form.Item
										layout="vertical"
										label={
											<Space>
												Drill Down Measure
												<Text className="custom-optional-text">(optional)</Text>
											</Space>
										}
										name={"drilldown"}
									>
										<Select
											// searchValue="label"
											optionFilterProp="label"
											showSearch
											placeholder="Drill down"
											options={getMeasureOptions}
											allowClear
											optionRender={(oriOption) => {
												let menu: any = customDashboardModules.find(
													(module: any) =>
														module.key === oriOption.data?.menuKey
												);

												return (
													<Space>
														{oriOption.label}
														{menu ? (
															<span style={{ opacity: "0.6" }}>
																({menu.title})
															</span>
														) : null}
													</Space>
												);
											}}
										/>
									</Form.Item>
								</Col>
								<Col flex={1}></Col>
							</Row>
						),
					},
					{
						key: "tags",
						label: "Tags",
						children: (
							<Row>
								<Col span={6}>
									<Modal
										destroyOnClose
										title={"Manage Tags"}
										onCancel={() => setTagsModal(false)}
										open={tagsModal}
										okButtonProps={{ style: { display: "none" } }}
									>
										<Table
											bordered
											size="small"
											dataSource={visualTags}
											components={{
												body: { cell: EditableCell, row: EditableRow },
											}}
											columns={columns as ColumnTypes}
										/>
									</Modal>
									<Form.Item
										layout="vertical"
										label={<Space>Tags</Space>}
										name={"tags"}
									>
										<Select
											// searchValue="label"
											mode="multiple"
											optionFilterProp="label"
											showSearch
											placeholder="Tags"
											options={visualTags.map((tag) => ({
												label: tag.label,
												value: tag._id,
											}))}
											allowClear
											tagRender={(props) => {
												const onPreventMouseDown = (
													e: React.MouseEvent<HTMLSpanElement>
												) => {
													e.preventDefault();
													e.stopPropagation();
												};

												if (visualTagsRecord[props.value])
													return (
														<Tag {...props} onMouseDown={onPreventMouseDown}>
															{props.label}
														</Tag>
													);

												return (
													<Tag {...props} onMouseDown={onPreventMouseDown}>
														Deleted tag
													</Tag>
												);
											}}
											dropdownRender={(menu) => (
												<>
													{menu}
													<Divider style={{ margin: "8px 0" }} />
													<Space>
														<Input
															value={tagLabel}
															ref={inputRef}
															onChange={(e) => {
																const rawValue = e.target.value;
																const cleanedValue = rawValue
																	.replace(/\s+/g, "_")
																	.toLowerCase(); // Replace spaces with underscores and convert to lowercase
																setTagLabel(cleanedValue);
															}}
															onKeyDown={(e) => e.stopPropagation()}
														/>
														<Button onClick={addTag}>Add Tag</Button>
													</Space>
												</>
											)}
											// optionRender={(oriOption) => {
											// 	let menu: any = customDashboardModules.find(
											// 		(module: any) =>
											// 			module.key === oriOption.data?.menuKey
											// 	);

											// 	return (
											// 		<Space>
											// 			{oriOption.label}
											// 			{menu ? (
											// 				<span style={{ opacity: "0.6" }}>
											// 					({menu.title})
											// 				</span>
											// 			) : null}
											// 		</Space>
											// 	);
											// }}
										/>
									</Form.Item>
									<Button onClick={() => setTagsModal(true)}>
										Manage Tags
									</Button>
								</Col>
								<Col flex={1}></Col>
							</Row>
						),
					},
				]}
			/>
			{/* </Spin> */}
		</Modal>
	);
};

const cleanTargets = (targets: TargetProps) => {
	const targetsEntries = Object.entries(targets).map(([k, v]: any) => {
		const targetEntries = Object.entries(v);
		const cleanedTarget = targetEntries?.map(([_k, _v]) => {
			// excluded type casting the _id to number
			if (_k === "_id") {
				return [_k, _v];
			}
			return [_k, Number(_v) ? Number(_v) : 0];
		});

		const cleanedTargetFromEntries = Object.fromEntries(cleanedTarget);

		return [k, cleanedTargetFromEntries];
	});

	const targetsFromEntries = Object.fromEntries(targetsEntries);

	return targetsFromEntries;
};
export default VisualModal;
