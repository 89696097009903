import { Tenant } from "./_exports";
import Emitter from "../EventEmitter";
import { api } from "../../contexts/AuthContext";

export const getCustomDashboard = (
	filter: any
): Promise<Record<string, any>> => {
	return new Promise((resolve, reject) => {
		api
			.get(`/custom-dashboard/get-dashboard`, {
				params: { tenantid: Tenant, ...filter },
			})
			.then((response) => {
				resolve(response.data);
			})
			.catch((e: any) => {
				reject(e);
			});
	});
};

export const createCustomDashboard = (dashboard: any) => {
	const { layout } = dashboard;
	return new Promise((resolve, reject) => {
		api
			.post(`/custom-dashboard/create-dashboard`, {
				layout,
				tenantid: Tenant,
			})
			.then((response) => {
				resolve(response.data);
			})
			.catch((e: any) => {
				console.log("err", e);
				reject(e);
			});
	});
};

export const updateCustomDashboard = (_id: any, dashboard: any) => {
	const { layout } = dashboard;
	return new Promise((resolve, reject) => {
		api
			.put(
				`/custom-dashboard/update-dashboard`,
				{ layout },
				{ params: { _id } }
			)
			.then((response) => {
				resolve(response);
			})
			.catch((e: any) => {
				console.log("err", e);
				reject(e);
			});
	});
};

export const deleteCustomDashboard = (layoutid: string) => {
	return new Promise((resolve, reject) => {
		api
			.delete(`/custom-dashboard/delete-dashboard`, {
				data: { id: layoutid, tenantid: Tenant },
			})
			.then((_data) => {
				resolve(_data);
			})
			.catch((e: any) => {
				console.log("err", e);
				reject(e);
			});
	});
};
