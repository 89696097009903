import { Popconfirm, Space, Table, TableProps, Typography } from "antd";
import { Dispatch, useContext, useMemo } from "react";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import MeasureDeleteHandler from "./MeasureDeleteHandler";
import { CustomDashboardContext } from "../contexts/context";
import CustomParagraph from "./CustomParagraph";
import useMenu from "../hooks/useMenu";
import { useCustomDashboard } from "../contexts/CustomDashboardContext";
import { useComponent } from "../contexts/ComponentContext";
dayjs.extend(utc);
dayjs.extend(timezone);

const { Link } = Typography;

interface MeasureListProps extends TableProps<any> {
	measures?: Array<any>;
	state: any;
	dispatch: Dispatch<any>;
}

const MeasureList = ({
	measures = [],
	state,
	dispatch,
	...restProps
}: MeasureListProps) => {
	const { state: customDashboardState, measureDispatch  } = useContext(CustomDashboardContext);
	const { customDashboardModules } = useMenu();
	const { menuItem, isOwner, isViewer } = useComponent();
	const { visuals } = useCustomDashboard();

	const hasPrevious = localStorage?.getItem(`menu.${menuItem.key}`);

	const measure = useMemo(() => {
		if (hasPrevious) return JSON.parse(hasPrevious);
		return null;
	}, [hasPrevious]);

	const columns: TableProps<MeasureType>["columns"] = useMemo(() => {
		return [
			{
				dataIndex: "incId",
				title: "ID",
				align: "center",
				render: (value) => <div style={{ height: "100%" }}>{value}</div>,
			},
			{
				dataIndex: "name",
				title: "Name",
				width: 200,
				render: (value) => <div style={{ height: "100%" }}>{value}</div>,
			},
			{
				dataIndex: "description",
				title: "Description",
				width: 300,
				render: (value) => <CustomParagraph>{value}</CustomParagraph>,
			},
			{
				dataIndex: "menuKey",
				title: "Mastered",
				width: 300,
				render: (value) => {
					if (value) {
						let foundModule: any = customDashboardModules.find(
							(module: any) => value === module.key
						);
						value = foundModule?.title;
					}
					return <CustomParagraph>{value}</CustomParagraph>;
				},
			},
			{
				dataIndex: "lastUpdatedAt",
				title: "Latest update",
				width: 190,
				render: (value) => <div style={{ height: "100%" }}>{value}</div>,
			},
			{
				dataIndex: "createdBy",
				title: "Created by",
				render: (value) => <div style={{ height: "100%" }}>{value}</div>,
			},
			{
				dataIndex: "actions",
				title: "Actions",
				align: "center",
				render: (value, record) => {
					// To check if an existing measure was detached from the mastered custom dashboard due to deletion of the mastered custom dashboard
					// Doing it this way for existing measures that does not have mastered information until it was edited and saved with the mastered information

					const mastered = customDashboardModules?.find(
						(module) => module.key === record.menuKey
					);

					const isMastered = !mastered || record.menuKey === menuItem.key;
					const actionable = !customDashboardState.editMode && isMastered;

					return (
						<Space
							style={{
								width: "max-content",
								height: "100%",
								alignItems: "start",
							}}
						>
							{isOwner && isMastered ? (
								measure ? (
									<>
										<Popconfirm
											title="Unsaved measure"
											description="You have unsaved changes. Would you like to restore them?"
											okText="Restore Changes"
											okButtonProps={{ type: "primary" }}
											cancelText="Discard Changes"
											onCancel={() => {
												handleEdit(record);
												localStorage.removeItem(`menu.${menuItem.key}`);
											}}
											disabled={!actionable}
											onConfirm={() => {
												dispatch({
													type: "RESTORE_MEASURE",
													payload: measure,
												});
											}}
										>
											<Link disabled={!actionable}>Edit</Link>
										</Popconfirm>
										<MeasureDeleteHandler
											targetId={record.id}
											record={record}
											actionable={actionable}
										/>
									</>
								) : (
									<>
										<Link disabled={!actionable} onClick={handleEdit(record)}>
											Edit
										</Link>
										<MeasureDeleteHandler
											targetId={record.id}
											record={record}
											actionable={actionable}
										/>
									</>
								)
							) : (
								<Link
									disabled={customDashboardState.editMode}
									onClick={handleViewMeasure(record)}
								>
									View
								</Link>
							)}
						</Space>
					);
				},
			},
		];
	}, [
		measure,
		isOwner,
		menuItem.key,
		visuals,
		customDashboardModules,
		customDashboardState.editMode,
	]);

	const handleEdit = (measure: MeasureType) => {
		return () => measureDispatch({ type: "EDIT_MEASURE", payload: measure });
	};

	const handleViewMeasure = (measure: MeasureType) => {
		return () => measureDispatch({ type: "VIEW_MEASURE", payload: measure });
	};

	return (
		<Table
			className="measure-list-table"
			dataSource={measures}
			bordered
			size="small"
			pagination={false}
			// tableLayout="fixed"
			style={{ overflowY: "scroll" }}
			columns={columns}
			{...restProps}
		>
			{/* {renderColumns} */}
		</Table>
	);
};

const generateDataSource = (measures: MeasureType[]) => {
	if (measures?.length === 0) return [];
	return measures.map((measure) => ({ ...measure, key: measure.id }));
};

export default MeasureList;
