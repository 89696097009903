import axios from "axios";
import { createContext, useLayoutEffect } from "react";
import { Tenant } from "../services/api-server/_exports";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../utils/utils";
import Cookies from "js-cookie";
import dayjs from "dayjs";

let userCheck: any = false;

type AuthProviderProps = {
	children: React.ReactNode;
};

const AuthContext = createContext(null);

const restrictedRedirects = [`/`, `/${Tenant}`, `/${Tenant}/`];
const restricted = [
	`/${Tenant}/login`,
	`/${Tenant}/logout`,
	`/${Tenant}/login/`,
	`/${Tenant}/logout/`,
];

export const url =
	process.env.NODE_ENV === "production"
		? `${window.location.origin}/${window.location.pathname.split("/")[1]}/api`
		: (process.env.REACT_APP_SERVER_URL_DEVELOPMENT as string);

export const api = axios.create({ baseURL: url, withCredentials: true });

const AuthProvider = ({ children }: AuthProviderProps) => {
	const dispatch = useDispatch();
	const user = useSelector((state: any) => state.user);

	useLayoutEffect(() => {
		let timeout: any = null;
		const fetchMe = async () => {
			const currentUrl = window.location.href.replace(/\/$/, "");
			const segments = currentUrl.split("/");
			const lastPath = segments[segments.length - 1];

			try {
				const myExpCookie = await Cookies.get(`${Tenant}:exp`);
				if (myExpCookie) {
					const expDate = dayjs.unix(parseInt(myExpCookie as string));
					const currentDate = dayjs(new Date());

					// GET exp and check lt 20m || mt 20m after exp
					if (
						currentDate.isAfter(dayjs(expDate).subtract(20, "minutes")) &&
						currentDate.isBefore(dayjs(expDate).add(20, "minutes"))
					) {
						await api.get("/refreshToken");
					} else if (currentDate.isAfter(dayjs(expDate).add(20, "minutes"))) {
						if (lastPath !== "login") {
							localStorage.setItem("redirectURL", window.location.href);
						}
						dispatch({ type: "LOGOUT", payload: true });
						logout(true);
						return false;
					}

					// if valid or expired run /me
					const response = await api.get("/me");
					const user = response.data;

					// Commented until further notice
					// dispatch({type:'LOGIN_REGISTER'})
					// dispatch({type:'SET_SSO_TOKEN'})
					if (!userCheck) {
						dispatch({ type: "LOGIN", payload: {} });
						dispatch({ type: "SET_USER", payload: user });
						dispatch({ type: "ROLE", payload: user?.roles || [] });
						userCheck = true;
					}

					// Check if login page

					// console.log({ user });
					// console.log({ loc: window.location.href });
					// window.location.href = `/${Tenant}`

					// Redirect user to prev URL
					const redirect_url = localStorage.getItem("redirectURL");
					if (redirect_url) {
						localStorage.removeItem("redirectURL");
						let split = window.location.pathname.split("/");
						if (split[1] === Tenant) {
							window.location.href = redirect_url;
						} else {
							window.location.href = `/${Tenant}`;
						}
					}
					timeout = setTimeout(fetchMe, 5 * 60 * 1000); // 5 minutes interval
					return true;
				} else {
					if (lastPath !== "login") {
						if (userCheck) {
							// Redirect user to prev URL
							dispatch({ type: "LOGOUT", payload: true });
							logout(true);
							return false;
						} else {
							const currentUrl = window.location.href.replace(/\/$/, "");
							if (
								currentUrl ==
								`${window.location.origin}/${
									window.location.pathname.split("/")[1]
								}`
							) {
								window.location.href = `${window.location.origin}/${
									window.location.pathname.split("/")[1]
								}/login`;
							} else {
								localStorage.setItem("redirectURL", window.location.href);
								window.location.href = `${url}/login`;
							}
						}
					}
				}
			} catch (error) {
				if (axios.isAxiosError(error)) {
					// console.log(error);

					if (
						error.response?.status === 403 &&
						error.response.data === "Unauthorized"
					) {
						if (lastPath !== "login") {
							localStorage.setItem("redirectURL", window.location.href);
							dispatch({ type: "LOGOUT", payload: true });
							logout(true);
						}
					}
				} else {
					console.log("An Unhandled function has occurred");
					if (lastPath !== "login") {
						localStorage.setItem("redirectURL", window.location.href);
					}
					dispatch({ type: "LOGOUT", payload: true });
					logout(true);
				}
				return false;
			}
		};

		fetchMe();
		return () => clearTimeout(timeout);
	}, [dispatch]);

	return <AuthContext.Provider value={null}>{children}</AuthContext.Provider>;
};

export { AuthProvider };
