import { Button, Form, Input, Modal, ModalProps, Popconfirm } from "antd";
import { sendAnnouncementEmail } from "../../services/api-server/sendgrid";
import { useSelector } from "react-redux";
import { useAccessRequestContext } from "../../contexts/AccessRequestContext";
import { useComponent } from "../../contexts/ComponentContext";

type AnnouncementModalProps = ModalProps & {};

const { TextArea } = Input;

const AnnouncementModal = ({
	onCancel = () => {},
	...restProps
}: AnnouncementModalProps) => {
	const [form] = Form.useForm();
	const mode = useSelector((state: any) => state.mode);
	const testRecipients = useSelector((state: any) => state.testRecipients);
	const { roleViewers, viewers, owners } = useAccessRequestContext();
	const { menuItem } = useComponent();

	const handleFinish = async (e: any) => {
		try {
			const values = await form.validateFields();
			const { subject: title, message } = values;

			const receivers = [...roleViewers, ...viewers, ...owners]
				?.filter((viewer: any) => viewer?.mail)
				.map((viewer: any) => viewer.mail);

			await sendAnnouncementEmail({
				component: menuItem?.component,
				component_title: menuItem?.title,
				redirect_url: `${window.location.origin}${window.location.pathname}`,
				receivers:
					mode === "testing"
						? testRecipients?.map((recipient: any) => recipient?.email)
						: receivers, // receivers will be all component viewers
				message: message?.trim(),
				title: title?.trim(),
			});
			onCancel(e);
			form.resetFields();
		} catch (error) {
			// Form validation failed
		}
	};

	return (
		<Modal
			{...restProps}
			onCancel={(e) => {
				onCancel(e);
				form.resetFields();
			}}
			maskClosable={false}
			destroyOnClose
			title="Send Component Announcement"
			okText="Send email"
			modalRender={(dom) => (
				<Form
					requiredMark={"optional"}
					autoComplete="off"
					form={form}
					layout="vertical"
				>
					{dom}
				</Form>
			)}
			footer={(
				originNode: React.ReactNode,
				extra: {
					OkBtn: React.FC;
					CancelBtn: React.FC;
				}
			) => (
				<>
					<extra.CancelBtn />
					<Popconfirm
						title={`Send this announcement to ${
							mode === "testing"
								? testRecipients?.length
								: roleViewers?.length + viewers?.length + owners?.length
						} component user(s)?`}
						onConfirm={handleFinish}
						okText="Yes, send"
					>
						<Button type="primary">Send email</Button>
					</Popconfirm>
				</>
			)}
		>
			<Form.Item
				rules={[
					{
						required: true,
						message: "Please enter subject",
						validator: (rule, value) => {
							if (!value?.trim()) {
								return Promise.reject(new Error("No empty spaces allowed"));
							} else {
								return Promise.resolve();
							}
						},
					},
				]}
				name={"subject"}
				label="Subject"
			>
				<Input placeholder="Summary....." />
			</Form.Item>
			<Form.Item rules={[{ required: true }]} name={"message"} label="Message">
				<TextArea placeholder="Describe the changes made..." />
			</Form.Item>
		</Modal>
	);
};

export default AnnouncementModal;
